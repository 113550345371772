import React,{useRef} from 'react';
import Navbar from '../../components/LandingComponents/Navbar';
import styles from './contact.module.css'
import arcImg from '../../assets/images/shapearc.png'
import Footer from '../../components/LandingComponents/Footer';
import { Form, Formik } from "formik";
import { contactValidator } from "../../validationSchema/validator";
import { connect } from 'react-redux';
import { contactSupport } from '../../store/actions/auth';


const ContactPage = ({handleContact}) => {

    const ref = useRef()

   // func
    const handleSubmit = async (values) => {
        // await login(values);
        console.log(values)
        await handleContact(values)

         // reset the form
         setTimeout(() => {
            ref.current.reset();
        }, 3000);
    };

    return ( 
        <>
            <Navbar />
            <div className={styles.contact_section}>
                <div className='contain'>

                    <div className={styles.titleDiv}>
                        <img src={arcImg} alt="arcImg" className='img-fluid' />
                        <h3 className={styles.title}>Contact Us</h3>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-lg-5 mb-5 mb-lg-0'>

                            <div>
                                <p className={styles.contact_info}>CardBarter is here to help you;
                                    <br />
                                    Our experts are available to answer any 
                                    questions you might have. We’ve got the answers.
                                </p>
                            </div>

                            <div className='mt-4'>
                                <h6 className={styles.caption}>Call Us</h6>
                                <p className={styles.contact_info}>Give us a call for any questions or issues you may have.</p>
                            </div>


                            <div className='mt-4'>
                                <h6 className={styles.caption}>Email Us</h6>
                                <p className={styles.contact_info}>Don't hesitate to e-mail us if you have any questions, complaints, or any other issues.</p>
                            </div>    

                              <div className='mt-4'>
                                <h6 className={styles.caption}>Live Chat</h6>
                                <p className={styles.contact_info}>If you need instant support, take advantage of our live chat feature.</p>
                            </div>    

                            <div className='mt-4'>
                                  <h6 className={styles.caption}>Whatsapp </h6>
                                <p className={styles.contact_info}>If you need assistance, you can always reach out to us via Whatsapp (+2349040337331).</p>
                            </div>

                        </div>

                        <div className='col-lg-7'>

                        <div>
                          {/* form submission */}
                            <Formik
                            onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                            }
                            validationSchema={contactValidator}
                            initialValues={{ firstName: "", lastName: "", email: "", message: "" }}
                            >
                            {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                            }) => (
                                <Form ref={ref} onSubmit={handleSubmit}>  
                                <div className={styles.form_div}>    

                                    <div className='row'>
                                     <div className='col-lg-6'>
                                            <div className={`mb-3 ${styles.input_container}`}>
                                                <label>First Name</label>
                                                <div style={{position: 'relative'}}>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="John"
                                                    className={styles.input_style}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.firstName}
                                                />
                                                </div>
                                                <small style={{ color: "#dc3545" }}>
                                                    {touched.firstName && errors.firstName}
                                                </small>
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className={`mb-3 ${styles.input_container}`}>
                                                <label>Last Name</label>
                                                <div style={{position: 'relative'}}>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Doe"
                                                    className={styles.input_style}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                />
                                                </div>
                                                <small style={{ color: "#dc3545" }}>
                                                    {touched.lastName && errors.lastName}
                                                </small>
                                            </div>
                                        </div>

                                    </div>
                                
                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label>Email</label>
                                        <div style={{position: 'relative'}}>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="johndoe@gmail.com"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        </div>
                                        <small style={{ color: "#dc3545" }}>
                                            {touched.email && errors.email}
                                        </small>
                                    </div>

                                <div className={`mb-2 ${styles.input_container}`}>
                                    <label>Your Message</label>
                                    <div style={{position: 'relative'}}>
                                    <textarea
                                        type="text"
                                        name="message"
                                        placeholder="Drop your message here"
                                        className={styles.input_style}
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        rows='8'
                                        style={{resize: 'none'}}
                                    />
                                    </div>
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.message && errors.message}
                                    </small>
                                </div>
                               
                                <div className='mt-3'>
                                    <button
                                     type='submit'
                                    disabled={isSubmitting}
                                    className={`btn ${styles.btn_submit}`}
                                    >
                                        Send Message
                                 </button>
                                </div>

                                <div className='mt-3'>
                                    <p className={styles.contact_footer}>Feel free to get in touch with us through our channels</p>
                                </div>

                                </div>
                                </Form>
                                )}
                            </Formik>
                         </div>

                        </div>
                    </div>

                </div>
              </div>
              <Footer />
        </>
     );
}


  const mapDispatchToProps = (dispatch) => {
    return {
        handleContact: (val) => dispatch(contactSupport(val))
    };
  };
 
export default connect(null, mapDispatchToProps)(ContactPage);