import React, { useEffect } from 'react'
import OnBoardLeftLayout from '../../components/OnboardingSidebar/sidebar'
import styles from './auth.module.css'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import Button from '../../components/Button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { registerValidator } from '../../validationSchema/validator'
import { connect } from 'react-redux'
import { clearSignUp, signUp } from '../../store/actions/auth'

const Signup = ({register, clearRegistration, signup_success}) => {

  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    await register(values);
  }

  useEffect(()=>{
    if (signup_success) {
      navigate("/verifyaccount");
      setTimeout(() => {
        clearRegistration();
      }, 2000);
   }  
  },[signup_success, clearRegistration, navigate])


  return (
    <>
      <div className={styles.Auth_div}>
        <div className="row no-gutters">
          <div className="col-lg-5 d-none d-lg-block">
            <OnBoardLeftLayout classname={styles.onboarding_img} />
          </div>
          <div className="col-lg-7 col-sm-12 col-md-12 col-xs-12">
            <div className={styles.auth_right_sect}>
              <div>
                <Formik
                  onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                  }
                  validationSchema={registerValidator}
                  initialValues={{
                    email: '',
                    firstname: '',
                    lastname: '',
                    phone: '',
                    password: '',
                    confirm_password: '',
                  }}
                >
                  {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form className={styles.form_div} onSubmit={handleSubmit}>
                      <div>
                        <div>
                          <h3 className={styles.signup}>Sign Up</h3>

                          <p className={`mt-3 ${styles.signup_title}`}>
                            Fill the form to sign up.
                          </p>
                        </div>

                        <div className={`mt-4 mb-3 ${styles.input_container}`}>
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstname"
                            placeholder="Folalu"
                            className={styles.input_style}
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.firstname && errors.firstname}
                          </small>
                        </div>

                        <div className={`mt-4 mb-3 ${styles.input_container}`}>
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastname"
                            placeholder="Timothy"
                            className={styles.input_style}
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.lastname && errors.lastname}
                          </small>
                        </div>

                        <div className={`mb-3 ${styles.input_container}`}>
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="tomiczilla@gmail.com"
                            className={styles.input_style}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.email && errors.email}
                          </small>
                        </div>

                        <div className={`mb-3 ${styles.input_container}`}>
                          <label>Phone Number</label>

                          <input
                            type="tel"
                            name="phone"
                            placeholder="08161885549"
                            className={styles.input_style}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.phone && errors.phone}
                          </small>
                        </div>

                        <div className={`mb-3 ${styles.input_container}`}>
                          <label>Password</label>

                          <input
                            type="password"
                            name="password"
                            placeholder="**************"
                            className={styles.input_style}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.password && errors.password}
                          </small>
                        </div>

                        <div className={`mb-3 ${styles.input_container}`}>
                          <label>Confirm passsword</label>

                          <input
                            type="password"
                            name="confirm_password"
                            placeholder="**************"
                            className={styles.input_style}
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.confirm_password &&
                              errors.confirm_password}
                          </small>
                        </div>

                        <div className="mt-4">
                          <Button title="Sign Up" disabled={isSubmitting} />
                        </div>

                        <div className="text-center mt-3">
                          <p className={styles.form_div_signup}>
                            Already have an account?{' '}
                            <Link to="/login">Sign In</Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className={styles.footer}>
                <img src={CBlogo_footer} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) =>{
  return{
      signup_success: state.auth.signup_success,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
      register: (creds) => dispatch(signUp(creds)),
      clearRegistration: (creds) => dispatch(clearSignUp(creds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
