import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from "./components/ScrollWrapper/ScrollToTop";

// private routes
import UserRoute from "./components/PrivateRoutes/userRoute";

import ForgotPassword from './pages/Auth/forgot-password'
import LoginPage from './pages/Auth/login'
import Signup from './pages/Auth/signup'
import ContactPage from './pages/Contact/contact'
import FAQPage from './pages/FAQS/faq'
import LandingPageLayout from './pages/LandingPage/home'
import NotFound from './pages/NotFound/NotFound'
import ResetPassword from './pages/Auth/reset-password'
import VerifyAccount from './pages/Auth/verify-account'
import VerificationStatus from './pages/Auth/verification-status'
import RateCalculatorPage from './pages/Rates/rates'
import TermsPage from './pages/PrivacyPage/terms'
import PrivacyPage from './pages/PrivacyPage/privacy'
import UsersDashboard from './pages/Users/Dashboard/dashboard'
import UsersTrade from './pages/Users/Trades/trades'
import UserCreatePinPage from './pages/Users/Security/CreatePin'
import UserManagePin from './pages/Users/Security/ManagePin'
import AMLPolicyPage from './pages/PrivacyPage/AMLPolicy'
import UserProfile from './pages/Users/Profile/profile'
import UserAccountsPage from './pages/Users/Accounts/accounts';
import UserWalletPage from './pages/Users/Wallets/wallets';
import UserWithdrawPage from './pages/Users/Withdraw/withdraw';
import UserTradeDetailsPage from './pages/Users/Trades/tradeDetails';
import UserStartTradePage from './pages/Users/Trades/StartTrade';
import UserTransactionDetailsPage from './pages/Users/Wallets/walletDetails';
import UserNotificationsPage from './pages/Users/Notifications/notifications';
import UserChangePin from './pages/Users/Security/ChangePin';
import UserChangePassword from './pages/Users/Security/ChangePassword';
import UserResetPin from './pages/Users/Security/ResetPin';
import UserResetPinToken from './pages/Users/Security/ResetPinToken';
import UserSetNewPin from './pages/Users/Security/SetNewPin';

function App() {
  return (
    <>
      <Router>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<LandingPageLayout />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/verifyaccount" element={<VerifyAccount />} />
          <Route path="/verifyemail/auth/activate/:code" element={<VerificationStatus />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/ratecalculator" element={<RateCalculatorPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/amlpolicy" element={<AMLPolicyPage />} />

          <Route path="/dashboard" element={
                 <UserRoute>
                    <UsersDashboard />
                 </UserRoute>
                   
                    } />
          <Route path="/start-trade" element={
                          <UserRoute>
                            <UserStartTradePage />
                        </UserRoute>
                        } />
          <Route path="/trades" element={   
                          <UserRoute>
                           <UsersTrade />
                          </UserRoute>
                          } />
          <Route path="/trade/details/:id" element={
                   <UserRoute>
                    <UserTradeDetailsPage />
                    </UserRoute>
                    } />
          <Route path="/accounts" element={
                      <UserRoute>
                        <UserAccountsPage />
                        </UserRoute>
                        } />
          <Route path="/wallets" element={
                <UserRoute>
                    <UserWalletPage />
                    </UserRoute>
                    } />
          <Route path="/wallet/details/:id" element={
                     <UserRoute>
                      <UserTransactionDetailsPage />
                      </UserRoute>
                      } />
          <Route path="/withdraw-funds" element={
                   <UserRoute>
                        <UserWithdrawPage />
                      </UserRoute>
                    } />
          <Route path="/notifications" element={
                   <UserRoute>
                      <UserNotificationsPage />
                      </UserRoute>
                      } />
          <Route path="/profile" element={
                    <UserRoute>
                     <UserProfile />
                    </UserRoute>
                    } />
          <Route path="/security/onboarding-complete" element={
                 <UserRoute>
                  <UserCreatePinPage />
                  </UserRoute>
                  } />
          <Route path="/security/manage-pin" element={
                   <UserRoute>
                      <UserManagePin />
                    </UserRoute>
                  } />
          <Route path="/security/change-pin" element={
              <UserRoute>
               <UserChangePin />
               </UserRoute>
               } />
          <Route path="/security/change-password" 
                element={
                  <UserRoute>
                    <UserChangePassword />
                    </UserRoute>
                } 
            />
          <Route path="/security/reset-pin" element={
               <UserRoute>
                  <UserResetPin />
                  </UserRoute>
                } />
          <Route path="/security/reset-pin/token" element={
                 <UserRoute>
                  <UserResetPinToken />
                  </UserRoute>
                  } />
          <Route path="/security/set-pin" element={
                   <UserRoute>
                      <UserSetNewPin />
                  </UserRoute>
                  } />

          <Route path="*" element={<NotFound />} />
        </Routes>
        </ScrollToTop>
      </Router>
    </>
  )
}

export default App
