import React, { useEffect } from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './profile.module.css'
import user from '../../../assets/images/userProfile.svg'
import reverse_icon from '../../../assets/icons/reverse_icon.svg'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux';
import Moment from "react-moment";
import { getCompletedTradeCount } from '../../../store/actions/trade';

const UserProfile = ({phoneNumber, firstName, lastName, email, createdAt, completeCount, fetchCount}) => {

    useEffect(() =>{
        fetchCount()
    },[fetchCount])

    return ( 
        <>
            <UsersSidebar>
                <p className="titlehead">Profile</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                        <div className={`mt-5 ${styles.profileCard}`}>
                            <div className={styles.firstHalf}>
                                <div className={styles.userImg}>
                                    <img src={user} className='img-fluid' alt="user" />
                                </div>
                                <div className={`mt-3 ${styles.changeImg_div}`}>
                                    <img src={reverse_icon} width='20' height='20' className='img-fluid' alt="reverse" />
                                    <p className={styles.changeImg_text}>Change Image</p>
                                </div>
                                <div className={`mt-3 ${styles.profileHr}`}>
                                </div>
                                <div className={`mt-3 ${styles.profileName_Div}`}>
                                    <h6 className={styles.name}>{firstName ? firstName : 'N/A'} {lastName ? lastName : 'N/A'}</h6>
                                    <p className={`mb-0 mt-2 ${styles.date}`}>Joined: <Moment format="MMMM Do, YYYY">{createdAt}</Moment></p>
                                </div>
                                <div className={`mt-3 ${styles.profileHr}`}>
                                </div>
                                <div className='mt-3'>
                                    <h6 className={`mb-0 ${styles.tradeCount}`}>{completeCount ? completeCount : 0}</h6>
                                    <p className={`mt-1 ${styles.tradeInfo}`}>Trade(s) Completed</p>
                                </div>
                            </div>

                            <div className={styles.secondHalf}>
                               <div>
                                {/* form submission */}
                                <Formik   
                                enableReinitialize={true}
                                     initialValues={{ 
                                         firstname: firstName ? firstName : 'N/A', 
                                         lastname: lastName ? lastName: 'N/A', 
                                         email: email ? email : 'N/A',
                                          phonenumber: phoneNumber ? phoneNumber : 'N/A' }}
                                >
                                {({
                                    handleChange,
                                    isSubmitting,
                                    handleSubmit,
                                    handleBlur,
                                    values,
                                    touched,
                                    errors,
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                        <div className={`mb-3 ${styles.input_container}`}>
                                        <label>First Name</label>
                                        <div>
                                            <input
                                            type="text"
                                            name="firstname"
                                            placeholder="First Name"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstname}
                                            disabled
                                            />
                                        </div>
                                       
                                        </div>

                                        <div className={`mb-3 ${styles.input_container}`}>
                                        <label>Last Name</label>
                                        <div>
                                            <input
                                            type="text"
                                            name="lastname"
                                            placeholder="Last Name"
                                            className={styles.input_style}
                                            value={values.lastname}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled
                                            />
                                        </div>
                                       
                                        </div>

                                        <div className={`mb-3 ${styles.input_container}`}>
                                        <label>Email</label>
                                        <div>
                                            <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address"
                                            className={styles.input_style}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled
                                            />
                                        </div>
                                       
                                        </div>

                                        <div className={`mb-2 ${styles.input_container}`}>
                                        <label>Phone Number</label>
                                        <div>
                                            <input
                                            type="text"
                                            name="phonenumber"
                                            placeholder="Phone Number"
                                            className={styles.input_style}
                                            value={values.phonenumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled
                                            />
                                        </div>
                                       
                                        </div>
                                        
                                    </div>
                                    </Form>
                                )}
                                </Formik>
                               </div>

                            </div>

                        </div>

                  </div>
             </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
        phoneNumber: state.auth.profile.phoneNumber,
        firstName: state.auth.profile.firstName,
        lastName: state.auth.profile.lastName,
        email: state.auth.profile.email,
        createdAt: state.auth.profile.createdAt,
        completeCount: state.trade.tradeCount
    };
  };

  const mapDispatchToProps =(dispatch) =>{
      return{
        fetchCount : () => dispatch(getCompletedTradeCount()),
      }
  }
  
 
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);