import styles from '../Button/Button.module.css'

// I MADE A BUTTON COMPONENT BECAUSE ALL THE BUTTONS ON THE AUTH FOLDER HAVE THE SAME DESIGNS
const Button = ({ title, onclick, disabled }) => {
  return (
    <>
      <button 
        type='submit'
        className={`btn ${styles.btn_submit}`}
        disabled={disabled}
        onClick={onclick}>
        {title}
      </button>
    </>
  ) 
}

export default Button
