import React,{useEffect, useRef} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { ChangePinValidator } from '../../../validationSchema/validator'
import { connect } from 'react-redux';
import { ChangePin, clearPinSuccess } from '../../../store/actions/auth';

const UserChangePin = ({savePin, clearPin, pinsuccess}) => {

    const ref = useRef()

      // submit func
  const handleSubmit = async (values) => {
    await savePin(values)
  }


  useEffect(() => {
    if (pinsuccess) {
      // reset the form
      setTimeout(() => {
        ref.current.reset();
        clearPin();
      }, 2000);
    }
  }, [pinsuccess, clearPin]);


    return (  
        <>
            <UsersSidebar>
                 <p className="titlehead">Security</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.Card}`}>
                    <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                         <div>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you feel your pin is compromised? <br/>
                                Tap to change pin
                             </p>

                             <Link
                                to="/security/change-pin"
                                 className={`mt-3 btn ${styles.cta_btn} ${styles.cta_btn_active}`}
                                >
                                Change Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Reset Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Can't remember your password? <br/>
                                Tap to reset pin
                             </p>
                             <Link
                                to="/security/reset-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Reset Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Password</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you want to change your password? <br/>
                             Tap to change password
                             </p>
                             <Link
                                to="/security/change-password"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Password
                            </Link>
                         </div>

                     </div>

                     <div className={`${styles.secondHalf}`}>

                     <h6 className={styles.title}>Change Pin</h6>

                        <div className={`mt-4`}>
                            {/* form submission */}
                            <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                                }
                                validationSchema={ChangePinValidator}
                                initialValues={{
                                    newpin: "",
                                    pin: "",
                                    confirm_pin: "",
                                }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                }) => (
                                <Form  ref={ref} onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                    <div
                                        className={`mt-3 mb-3 ${styles.input_container}`}
                                    >
                                        <label htmlFor="pin">
                                        Current Pin
                                        </label>
                                        <div>
                                        <input
                                            name="pin"
                                            type='password'
                                            placeholder="Enter your current pin"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.pin}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.pin &&
                                            errors.pin}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="newpin">New Pin</label>
                                        <div>
                                        <input
                                            type="password"
                                            name="newpin"
                                            placeholder="Enter a new pin"
                                            className={styles.input_style}
                                            value={values.newpin}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.newpin && errors.newpin}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="confirm_pin">Confirm Pin</label>
                                        <div>
                                        <input
                                            type="password"
                                            name="confirm_pin"
                                            placeholder="Confirm your pin"
                                            className={styles.input_style}
                                            value={values.confirm_pin}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.confirm_pin && errors.confirm_pin}
                                        </small>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_submit}`}
                                        >
                                        Change Pin
                                        </button>
                                    </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        </div>

                     </div>

                </div>




                 </div>
             </div>
        </>
    );
}
 


const mapStateToProps = (state) => {
    return {
      pinsuccess: state.auth.pinsuccess,
      isAuthenticated: state.auth.isAuthenticated,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      savePin: (values) => dispatch(ChangePin(values)),
      clearPin: () => dispatch(clearPinSuccess()),
    };
  }
   

export default connect(mapStateToProps, mapDispatchToProps)(UserChangePin);