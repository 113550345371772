const initState = {
    trades: [],
    latestTrades: [],
    tradesSearch: [],
    loader: false,
    tradeCount: 0,
    btnLoader: false,
    trade_success: false
  };
  
  const tradeReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Trades':
          return {
                ...state,
                trades: action.data,
                tradesSearch: action.data,
                loader: false
            }
        case 'LatestTrades':
            return{
                ...state,
                latestTrades: action.data
            }
        case "searchTrade":
            let res = state.tradesSearch.filter((val) => {
                return (
                val.tradeStatus.toLowerCase().includes(action.data.toLowerCase())
                ||
                val.subCategoryDetails.categoryname.toLowerCase().includes(action.data.toLowerCase())
                );
            });
        return {
            ...state,
            trades: res
         }
        case 'Loader':
            return{
                ...state,
                loader: true
            }
        case 'TradeCount':
            return{
                ...state,
                tradeCount: action.data
        }
        case 'TRADE_START':
            return{
                ...state,
                btnLoader: true
            }
        case 'TRADE_SUCCESS':
            return{
                ...state,
                btnLoader: false,
                trade_success: true
            }
        case 'TRADE_ERROR':
            return{
                ...state,
                btnLoader: false,
            }
        case 'clearCardTradeSuccess':
            return{
                ...state,
                trade_success: false
            }
      default:
        return state;
    }
  };
  
  export default tradeReducer;
  