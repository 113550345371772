import React from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './wallets.module.css'
import bank_icon from '../../../assets/icons/bank.svg'
import wallet_icon from '../../../assets/icons/wallet_icon.svg'
import check_icon from '../../../assets/icons/check-circle(green).svg'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "react-moment";

const UserTransactionDetailsPage = ({transactions, firstName, lastName}) => {

  const {id} = useParams()

  const transaction = transactions.find((val) => val._id === id);

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_badge
            break;
          case "Successful":
            result = styles.success_badge
            break;
          case "Failed":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };

    return (
        <>
         <UsersSidebar>
              <p className="titlehead">Transaction Details</p>
         </UsersSidebar>
         <div className="usermain">        
              <div className="contain">

              <div className={`mt-5 ${styles.walletCard}`}>
                     <div className={styles.firstHalf}>

                            <h6 className={`mb-0 ${styles.cardTitle}`}>Account Credited</h6>

                          
                          {/* bank */}
                         {
                           transaction && transaction.type === 'Debit'
                           &&
                           <div className={`mt-4 mb-4 ${styles.accountDetailsDiv}`}>
                           <div className={`d-none d-lg-block ${styles.accountDetailsIcon}`}>
                                <img src={check_icon} className='img-fluid' alt="check" />
                           </div>

                           <div className={`${styles.accountDiv}`}>
                                   <div className={styles.accountInfo}>
                                       <div className={styles.accountIcon}>
                                            <img src={bank_icon} alt="bank" className='img-fluid' />
                                       </div>
                                       <div>
                                           <h6 className={`mb-0 ${styles.accountNm}`}>{transaction? transaction.accountName:'N/A'}</h6>
                                           <p className={`mb-0 mt-1 ${styles.accountNo}`}>{transaction ? transaction.accountNumber:'N/A'}</p>
                                           <p className={`mb-0 mt-1 ${styles.bankTitle}`}>{transaction ? transaction.bankName:'N/A'}</p>
                                       </div>
                                   </div>    
                           </div>
                         </div>
                         }
                        
                      {/* wallet */}
                      {
                        transaction && transaction.type === 'Credit'
                        &&
                        <div className={`mt-4 mb-4 ${styles.accountDetailsDiv}`}>
                            <div className={`d-none d-lg-block ${styles.accountDetailsIcon}`}>
                                 <img src={check_icon} className='img-fluid' alt="check" />
                            </div>

                            <div className={`${styles.accountDiv}`}>
                                    <div className={styles.accountInfo}>
                                        <div className={styles.accountIcon}>
                                             <img src={wallet_icon} alt="wallet" className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={`mb-0 ${styles.accountNm}`}>{firstName} {lastName} </h6>
                                            <p className={`mb-0 mt-1 ${styles.bankTitle}`}>CardBarter Wallet</p>
                                        </div>
                                    </div>    
                            </div>
                          </div>
                      }

                    </div>

                    <div className={styles.secondHalf}>
                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Transaction Id</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{transaction ? transaction._id: 'trans1445555'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Transaction Type</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{transaction ? transaction.type: 'Transaction Type'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Narration</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{transaction ? transaction.narration : "Narration"}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Amount</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>NGN {transaction ? transaction.amount: '0.00'} </p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Date</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>
                                    <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                        {transaction ? transaction.createdAt : "2022-06-06T14:25:01.549Z"}
                                        </Moment>
                                    </p>
                                </div>

                                <div>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Status</h6>
                                    <div className='d-flex'>
                                      <p className={`mt-2 ${styles.info_subtitle} ${getStatusColor(transaction ? transaction.status : 'Pending')}`}>{transaction ? transaction.status : 'Pending'}</p>
                                     </div>
                                </div>

                         </div>

                      </div>


               </div>
        </div>
        </>
      );
}
 

const mapStateToProps = (state) => {
  return {
    transactions: state.wallet.transactions,
    firstName: state.auth.profile.firstName,
    lastName: state.auth.profile.lastName,
  };
};

export default connect(mapStateToProps)(UserTransactionDetailsPage);