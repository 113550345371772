import React from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './trades.module.css'
import ImageZoom from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Moment from "react-moment";

const UserTradeDetailsPage = ({trades}) => {

  const {id} = useParams()

  const trade = trades.find((val) => val._id === id);

      // mapping images
      const imageLayout = trade ? trade.imageUrl
      .filter((el) => el !== "")
      .map((item, index) => (
          <div key={index} className='col-lg-4 mb-3'>
           <div>
              <ImageZoom>
                  <img
                  alt="cards"
                  src={item}
                  className={styles.cardImage}
                  />
              </ImageZoom>
           </div>
          </div>
      ))
      :
      ""

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result =  styles.pending_badge
            break;
          case "Completed":
            result = styles.success_badge
            break;
          case "Declined":
            result =  styles.failed_badge
            break;
          default:
            break;
        }
        return result;
      };

      
      const numberWithCommas = (x) => { 
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
      }



    return (  
        <>
            <UsersSidebar>
            <p className="titlehead">Trade Details</p>
            </UsersSidebar>
            <div className="usermain"> 
                 <div className="contain">

                     <div className={`mt-5 ${styles.tradeCard}`}>
                         <div className={styles.firstHalf}>

                            <h6 className={`mb-0 ${styles.cardTitle}`}>Giftcard Images</h6>

                             {/* cards */}
                             <div className='row mt-lg-5 mt-4'>
                                  {
                                    imageLayout
                                }
                             </div>

                             {
                                trade.tradeStatus === 'Declined' &&
                                <>
                                  <div className='mt-4 mb-4'>
                                      <h6 className={styles.cardTitle}>Declined Reason</h6>
                                      <p className={styles.info_subtitle}>{trade.hasOwnProperty('declinedReason') ? trade.declinedReason.reasonText : "Declined"}</p>
                                  </div>
                                    
                                  <div className='mb-4'>
                                      <h6 className={styles.cardTitle}>Image(s) Attached for Declination</h6>
                                      <ImageZoom>
                                          <img
                                          alt="cards"
                                          src={trade.declinedReason.imageUrl === "" ? trade.imageUrl[0] : trade.declinedReason.imageUrl }
                                          className={`mt-2 ${styles.cardImage}`}
                                          />
                                      </ImageZoom>
                                  </div>
                                </>
                              }

                         </div>

                         <div className={styles.secondHalf}>
                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Transaction Id</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade._id: 'trans1445555'}</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Trade Type</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>Giftcard</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Card Name</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.subCategoryDetails.categoryname: 'N/A'}</p>
                                </div>

                                <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Card Category</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.subCategoryDetails.subcategoryname: 'N/A'}</p>
                                  </div>

                                  <div className='mb-4'>
                                      <h6 className={`mb-0 ${styles.info_title}`}>Comment</h6>
                                      <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.comment: 'N/A'}</p>
                                  </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Amount Traded</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? trade.cardAmount : 'N/A'} USD</p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Date Intialized</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>
                                      <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                        {trade ? trade.createdAt : "2022-06-06T14:25:01.549Z"}
                                        </Moment>
                                      </p>
                                </div>

                                <div className='mb-4'>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Amount To Get</h6>
                                    <p className={`mb-0 mt-1 ${styles.info_subtitle}`}>{trade ? numberWithCommas(trade.amount) : 'N/A'} Naira</p>
                                </div>

                                <div>
                                    <h6 className={`mb-0 ${styles.info_title}`}>Trade Status</h6>
                                    <div className='d-flex'>
                                      <p className={`mt-2 ${styles.info_subtitle} ${getStatusColor(trade? trade.tradeStatus : 'Pending')}`}>{trade? trade.tradeStatus: 'N/A'}</p>
                                     </div>
                                </div>

                         </div>

                      </div>



                  </div>
           </div>
        </>
    );
}


const mapStateToProps = (state) => {
  return {
    trades: state.trade.trades,
  };
};
 
export default connect(mapStateToProps)(UserTradeDetailsPage);