import React,{useState, useEffect} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './wallets.module.css'
import networth from '../../../assets/icons/networth.svg'
import earnings from '../../../assets/icons/earnings.svg'
import withdraw_icon from '../../../assets/icons/withdraw_icon.svg'
import briefcase from '../../../assets/icons/briefcase.svg'
import { Link, useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { Form, Formik } from 'formik'
import { filterValidator } from "../../../validationSchema/validator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFilteredTransactions, getTransactions, getWalletInfo } from '../../../store/actions/wallets';
import { connect } from 'react-redux';
import Moment from "react-moment";


const UserWalletPage = ({fetchTransactions, loader, transactions, fetchFilteredTransactions, fetchWalletInfo, walletinfo}) => {

    useEffect(()=>{
        let status = 'All'
        fetchTransactions(status)
        fetchWalletInfo()
      },[fetchTransactions,fetchWalletInfo])

    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate()

    const formatDate = (date) => {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
    };

      // submit func
  const handleSubmit = async (values) => {
    let res;
      res = {
        from_date: formatDate(startDate),
        to_date: formatDate(endDate),
        status: values.status,
      };
    console.log(res)
    await fetchFilteredTransactions(values.status, formatDate(startDate), formatDate(endDate))
  }


    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
        },
        {
            name: 'Amount',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`NGN ${row.amount}`}</span>
              ),
        },
        {
            name: 'Date',
            cell: (row) => (
                <span className={styles.recent_table_row}>
                   <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
              ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
            ),
        },
        {
            name: "Action",
            button: true,
            cell: (row) => (
              <button
              className="btn btn-sm btn-view"
              onClick={() => {
                ViewRequest(row._id);
              }}
            >
              View
            </button>
            ),
          },
    ];

    const ViewRequest = (id) =>{
        navigate(`/wallet/details/${id}`)
    }

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Successful":
            result = "completed_icon";
            break;
          case "Failed":
            result = "failed_icon";
            break;
          default:
            result = "failed_icon";
            break;
        }
        return result;
      };


    const numberWithCommas = (x) => { 
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }
   

   
    return (  
        <>
            <UsersSidebar>
              <p className="titlehead">Wallet</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                     <div className='row align-items-lg-center mt-5'>

                         <div className='col-lg-3 mb-lg-0 mb-4'>
                            <div className={styles.noBgCard}>
                                    <div className={styles.contentDiv}>
                                        <div className={styles.balanceImg}>
                                            <img src={networth} className='img-fluid' alt='networth' />
                                        </div>
                                        <div>
                                            <p className={styles.title}>Total Balance</p>
                                            <h5 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(walletinfo).length === 0 ? '0.00' : numberWithCommas(walletinfo.walletBalance)}</h5>
                                        </div>
                                    </div>
                            </div>         
                         </div>

                         <div className='col-lg-3 mb-lg-0 mb-4'>
                                <div className={styles.noBgCard}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.contentImg}>
                                                 <img src={earnings} className='img-fluid' alt='earnings' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Total Withdrawal</p>
                                                 <h5 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(walletinfo).length === 0 ? '0.00' : numberWithCommas(walletinfo.totalWithdrawal)}</h5>
                                             </div>
                                         </div>
                                </div>
                         </div>

                         <div className='col-lg-3 mb-lg-0 mb-4'>
                            <div className={styles.withdrawCard}>
                                    <div className={styles.contentDiv}>
                                        <div className={styles.withdrawImgDiv} style={{marginRight: '20px'}}>
                                            <img src={withdraw_icon} className='img-fluid'  alt='withdraw' />
                                        </div>
                                        <div>
                                            <p className={styles.title}>Last Credited</p>
                                            <h6 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(walletinfo).length === 0 ? '0.00' : numberWithCommas(walletinfo.lastCreditted)}</h6>
                                            <p className={`mb-0 mt-1 ${styles.duration}`}>Last updated</p>
                                        </div>
                                    </div>
                            </div>
                         </div>

                         <div className='col-lg-3'>

                             <Link to="/withdraw-funds" className={styles.funds_Div}>
                                <div className={styles.fundsImg}>
                                    <img src={briefcase} className='img-fluid' alt="case" />
                                </div>
                                <div>
                                    <p className={styles.fundsText}>Withdraw Funds</p>
                                </div>
                             </Link>

                         </div>
                     </div>

                     <div className='row mt-4 mt-lg-5'>
                         <div className='col-lg-4 mb-lg-0 mb-4'>

                             <div className={styles.filterCard}>

                                 <h6 className={`mb-0 ${styles.cardTitle}`}>Filter By</h6>

                               <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                    handleSubmit(values, setSubmitting)
                                }
                                validationSchema={filterValidator}
                                initialValues={{  status: "",}}
                                >
                                {({
                                    handleChange,
                                    isSubmitting,
                                    handleSubmit,
                                    handleBlur,
                                    values,
                                    touched,
                                    errors,
                                }) => (
                                    <Form onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                        <div className={`mt-4 mb-3 ${styles.input_container}`}>  
                                          <label htmlFor="startDate">From Date</label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    className={`${styles.filter_input_style}`}
                                                    dateFormat="yyyy-MM-dd"
                                                />
                                        </div>

                                          <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor="endDate">To Date</label>
                                            <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    className={`${styles.filter_input_style}`}
                                                    dateFormat="yyyy-MM-dd"
                                                />
                                        </div>

                                        <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor='status'>Status</label>
                                            <select
                                                name="status"
                                                values={values.status}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                defaultValue=""
                                                onBlur={handleBlur}
                                                className={`${styles.filter_input_style}`}
                                                id="status"
                                                >
                                                <option value="" disabled>
                                                        Select Status
                                                </option>
                                                <option value='All'>
                                                    All
                                                </option>
                                                <option value='Pending'>
                                                    Pending
                                                </option>
                                                <option value='Successful'>
                                                    Successful
                                                </option>
                                                <option value='Failed'>
                                                    Failed
                                                </option>
                                               
                                            </select>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.status && errors.status}
                                            </small>
                                        </div>

                                     <div className="mt-4">
                                        <button disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_filter}`}
                                        >Filter</button>
                                      </div>
                                     
                                    </div>
                                    </Form>
                                )}
                                </Formik>

                             </div>

                         </div>

                         <div className='col-lg-8'>
                            <div className={styles.transactTable}>
                                    <DataTable
                                        title="Wallet Transactions"
                                        pagination
                                        persistTableHead
                                        columns={columns}
                                        data={transactions}
                                        progressPending={loader}
                                    />
                            </div>
                         </div>
                     </div>
                     


                 </div>
            </div>
        </>
    );
}
 


const mapStateToProps = (state) => {
    return {
      transactions: state.wallet.transactions,
      loader: state.wallet.loader,
      walletinfo: state.wallet.walletInfo
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchTransactions: (status) => dispatch(getTransactions(status)),
      fetchWalletInfo: () => dispatch(getWalletInfo()),
      fetchFilteredTransactions: (status, from, end) => dispatch(getFilteredTransactions(status, from, end)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(UserWalletPage);