import React from 'react';
import styles from './landing.module.css'
import amazon from '../../assets/icons/amazon.svg'
import apple from '../../assets/icons/apple.svg'
import steam from '../../assets/icons/Steam.svg'
import walmart from '../../assets/icons/walmart.svg'
import playstore from '../../assets/icons/playstore.svg'

const CardsSection = () => {
    return (  
        <>
        <div className={styles.cards_section}>
            <div className='contain'>
                <div className={styles.cards_layout}>

                    <div className={`${styles.card_div}`}>
                        <img src={amazon} className='img-fluid' alt="amazon" />
                        <p className={`mt-1 ${styles.card_title}`}>Amazon</p>
                    </div>

                    <div className={`${styles.card_div}`}>
                        <img src={apple} className='img-fluid' alt="apple" />
                        <p className={`mt-1 ${styles.card_title}`}>App Store & iTunes</p>
                    </div>

                    <div  className={`${styles.card_div}`}>
                        <img src={steam} className='img-fluid' alt="steam" />
                        <p className={`mt-1 ${styles.card_title}`}>Steam</p>
                    </div>

                    <div  className={`${styles.card_div}`} >
                        <img src={walmart} className='img-fluid' alt="Walmart" />
                        <p className={`mt-1 ${styles.card_title}`}>Walmart</p>
                    </div>

                    <div  className={`${styles.card_div}`}>
                        <img src={playstore} className='img-fluid' alt="playstore" />
                        <p className={`mt-1 ${styles.card_title}`}>Google Play</p>
                    </div>

                    
                </div>

            </div>
        </div>
        </>
    );
}
 
export default CardsSection;