import React,{useEffect} from 'react'
import OnBoardLeftLayout from '../../components/OnboardingSidebar/sidebar'
import styles from './auth.module.css'
import CBlogo from '../../assets/images/CB-logo.svg'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import email from '../../assets/icons/email_icon.svg'
import password from '../../assets/icons/password_icon.svg'
import Button from '../../components/Button/Button'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { loginValidator } from '../../validationSchema/validator'
import { connect } from 'react-redux'
import { loginUser } from '../../store/actions/auth'

const LoginPage = ({login, isAuthenticated, isSetPin}) => {

  const navigate = useNavigate()

  // login func
  const handleSubmit = async (values) => {
    await login(values);
  }

  useEffect(()=>{
    if(isAuthenticated){
      if (isSetPin) {
        navigate('/dashboard')
     }
     else {
         navigate("/security/onboarding-complete");
     }    
    }
  },[isAuthenticated, isSetPin,navigate])

  return (
    <>
      <div className={styles.Auth_div}>
        <div className="row no-gutters">
          <div className="col-lg-5 d-none d-lg-block">
            <div>
              <OnBoardLeftLayout classname={styles.onboarding_img} />
            </div>
          </div>
          <div className="col-lg-7">
            <div className={styles.auth_right_sect}>
              <div className={styles.login_header}>
                <img src={CBlogo} alt="logo" className="img-fluid" />
                <p className="mt-1 mb-0">Trade Easy, Earn Quickly.</p>
              </div>
              <div className={`mt-lg-4 mt-3`}>
                {/* form submission */}
                <Formik
                  onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                  }
                  validationSchema={loginValidator}
                  initialValues={{ email: '', password: '' }}
                >
                  {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className={styles.form_div}>
                        <div className={`mb-3 ${styles.input_container}`}>
                          <label>Email</label>
                          <div style={{ position: 'relative' }}>
                            <input
                              type="email"
                              name="email"
                              placeholder="johndoe@gmail.com"
                              className={styles.input_style}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                            />
                            <img
                              src={email}
                              alt="email-icon"
                              className={styles.auth_icon_email}
                            />
                          </div>
                          <small style={{ color: '#dc3545' }}>
                            {touched.email && errors.email}
                          </small>
                        </div>

                        <div className={`mb-2 ${styles.input_container}`}>
                          <label>Password</label>
                          <div style={{ position: 'relative' }}>
                            <input
                              type="password"
                              name="password"
                              placeholder="*********"
                              className={styles.input_style}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <img
                              src={password}
                              alt="password-icon"
                              className={styles.auth_icon_password}
                            />
                          </div>
                          <small style={{ color: '#dc3545' }}>
                            {touched.password && errors.password}
                          </small>
                        </div>
                        <div className="text-right">
                          <Link
                            to="/forgotpassword"
                            className={`${styles.forgot_psw}`}
                          >
                            Forgot Password?
                          </Link>
                        </div>

                        <div className="mt-4">
                          <Button disabled={isSubmitting} title="Sign In" />
                        </div>

                        <div className="text-center mt-4">
                          <p className={styles.form_div_signup}>
                            Don’t have an account?{' '}
                            <Link to="/signup" className="ml-1">
                              Sign Up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              <div className={styles.footer}>
                <img src={CBlogo_footer} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) =>{
  return{
      isAuthenticated: state.auth.isAuthenticated,
      isSetPin: state.auth.isSetPin,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return{
      login: (creds) => dispatch(loginUser(creds)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
