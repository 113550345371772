import React,{useState, useEffect} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import PinInput from "react-pin-input";
import { connect } from 'react-redux';
import { SetPin } from '../../../store/actions/auth';
import cogoToast from 'cogo-toast';
import { useNavigate } from 'react-router-dom';


const UserCreatePinPage = ({createPin, pinload, isSetPin}) => {

    const navigate = useNavigate();

    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [valid, setValid] = useState(false);

    const handlePin = (val) => {
        setPin(val);
    };

    const handleConfirmPin = (val) => {
        setConfirmPin(val);
    };

    const handleSubmit = () => {
        // check if pin and confirm pin are correct
      if(pin === ''){
          cogoToast.info('Please enter a pin for your transactions!')
      }
      else{
        if (pin !== confirmPin) {
            setValid(true);
          } else {
            setValid(false);
            let val = {
              pin,
            };
            console.log(val)
            createPin(val);
          }
      }   
    };

    useEffect(() => {
        if (isSetPin) {
          navigate("/dashboard");
        }
    }, [isSetPin, navigate]);
    


    return ( 
        <>  
            <UsersSidebar>
                <p className="titlehead">Security</p>
             </UsersSidebar>
             <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.Card}`}>
                     <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                        <div className={styles.createPin_Sect}>
                            <div>
                                <h6 className={`mb-0 ${styles.title}`}>Transaction Pin</h6>
                                <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                                To complete your account setup, <br/>
                                Create a secure pin for your transactions
                                </p>
                            </div>
                        </div>
                      </div>


                      <div className={styles.secondHalf}>
                          <p className={`mb-0 ${styles.title}`}>Create Pin</p>
                          <p className={`mb-0 mt-1 ${styles.subtitle}`}>
                             Please setup your transaction pin.
                          </p>


                            {/* pin layout */}
                            <div className='mt-3'>
                                <p
                                className={`${styles.subtitle}`}
                                 >Set Pin</p>
                                <PinInput
                                length={4}
                                initialValue={pin}
                                focus
                                secret
                                type="numeric"
                                onChange={handlePin}
                                inputStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                inputFocusStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                />
                           </div>

                           <div className='mt-4'>
                                <p
                                className={`${styles.subtitle}`}>Confirm Pin</p>
                                <PinInput
                                length={4}
                                initialValue={pin}
                                focus
                                secret
                                type="numeric"
                                onChange={handleConfirmPin}
                                inputStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                inputFocusStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                />
                                 <small style={{ color: "rgb(220, 53, 69)" }}>
                                {valid ? "Pin must match!" : ""}
                                </small>
                           </div>

                           <div className="mt-4">
                                <button
                                type="submit"
                                onClick={handleSubmit}
                                 className={
                                     pinload ?
                                     `btn ${styles.btn_submit} ${styles.btn_submit_disabled}`
                                     :
                                     `btn ${styles.btn_submit}`
                                 }
                                
                                >
                                Create Pin
                                </button>
                            </div>



                     </div>



                 </div>


                 </div>
            </div>

        </>
     );
}
 

const mapStateToProps = (state) => {
    return {
      pinload: state.auth.pinload,
      isSetPin: state.auth.isSetPin,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      createPin: (values) => dispatch(SetPin(values)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(UserCreatePinPage);