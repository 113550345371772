const initState = {
    transactions: [],
    transactionSearch: [],
    loader: false,
    withdrawsuccess : false,
    WalletBalance: 0,
    walletInfo: {}
  };
  
  const walletReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Transactions':
          return {
                ...state,
                transactions: action.data,
                transactionSearch: action.data,
                loader: false
            }
    case "searchTransactions":
        let res = state.transactionSearch.filter((val) => {
            return (
            val.status.toLowerCase().includes(action.data.toLowerCase()) ||
            val.amount.toString().includes(action.data.toLowerCase()) 
            );
        });
    return {
        ...state,
        transactions: res
        }
    case 'Loader':
        return{
            ...state,
            loader: true
        }
    case 'Transaction_Error':
        return{
            ...state,
            loader: false
    }
    case 'WalletBalance':
        return{
            ...state,
            WalletBalance: action.data,
    }
    case 'WalletInfo':
        return{
            ...state,
            walletInfo: action.data
        }
   case 'WithdrawSuccess':
            return{
                ...state,
                withdrawsuccess: true,
            }
        case "Withdraw_Error":
            return {
                ...state,
                withdrawsuccess: false,
            };
        case "clearWithdrawal":
        return {
            ...state,
            withdrawsuccess: false,
        };
   
      default:
        return state;
    }
  };
  
  export default walletReducer;
  