import React from 'react';
import styles from './landing.module.css'
import stepsBg from '../../assets/images/stepsBack.png'
import heart from '../../assets/icons/heart.svg'

const StepsSection = () => {
    return ( 
        <>
            <div className={styles.steps_section}>
                <div className='contain'>

                    <div className='row'>

                        <div className='col-lg-6 mb-5 mb-lg-0'>

                            <div style={{position: 'relative'}}>
                                <img src={stepsBg} width='500' height='500' className='img-fluid' alt='getting started' />

                                <div className={styles.step_layout_div}>
                                    <div className={styles.step_lay}>
                                        <div className={styles.step_cirlce}>
                                            <img src={heart} alt="heart" width='20' height='20' className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={styles.step_name}>Hi, Welcome!</h6>
                                            <p className={`mt-1 ${styles.step_subtitle}`}>Let me show you how to get started easily.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='col-lg-6'>

                            <div>
                                <h3 className={styles.steps_title}>How do I get started?</h3>
                            </div>

                            <div className='row mt-4'>
                                <div className='col-lg-6 mb-4'>
                                    <div className={styles.steps_card}>
                                       <div className={styles.steps_circle}>
                                            <span>01</span>
                                        </div>

                                        <div className='mt-4'>
                                            <h6 className={styles.steps_text}>Create an Account</h6>
                                            <p className={`mt-3 ${styles.steps_caption}`}>
                                            Click on the ‘register button’ at the top right corner of the screen to create your own account.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 mb-4'>
                                    <div className={styles.steps_card}>
                                       <div className={styles.steps_circle}>
                                            <span>02</span>
                                        </div>

                                        <div className='mt-4'>
                                            <h6 className={styles.steps_text}>Upload your gift card</h6>
                                            <p className={`mt-3 ${styles.steps_caption}`}>
                                            Choose the gift card type and the most appropriate category. Input the gift card value and the naira equivalent will be displayed.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-6 mb-4 mb-lg-0'>
                                    <div className={styles.steps_card}>
                                       <div className={styles.steps_circle}>
                                            <span>03</span>
                                        </div>

                                        <div className='mt-4'>
                                            <h6 className={styles.steps_text}>Accept Our Rate</h6>
                                            <p className={`mt-3 ${styles.steps_caption}`}>
                                            Accept the rate offer and proceed to complete the transaction.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-6 '>
                                    <div className={styles.steps_card}>
                                       <div className={styles.steps_circle}>
                                            <span>04</span>
                                        </div>

                                        <div className='mt-4'>
                                            <h6 className={styles.steps_text}>Receive your Cash</h6>
                                            <p className={`mt-3 ${styles.steps_caption}`}>
                                            It will take a minute or less to complete a trade. After a successful trade, fill in your banking details and request to withdraw your money. Withdrawal is instantaneous following a successful trade.
                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                </div>

            </div>
        </>
     );
}
 
export default StepsSection;