import {GetApi, PostApi} from '../request'
import cogoToast from 'cogo-toast';

const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  

// get all trades
export const getTrades = (status) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/giftcard/trade/user?status=${status}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Trades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Trades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get lastest trades
export const getLatestTrades = () => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi(`trade/giftcard/trade/user?status=All&limit=5`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "LatestTrades", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "LatestTrades_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

// get all giftcards trade with filter
export const getFilteredTrades = (status, from_date, to_date) => {
    return async (dispatch, getState) => {
        dispatch({ type : 'Loader'})
      try {
        const res = await GetApi(`trade/giftcard/trade/user?status=${status}&from_date=${from_date}&to_date=${to_date}`, getToken(),);
        if (res.status === 200) {
          dispatch({ type: "Trades", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "Trades_Error", err: res.data });
        }
      } catch (err) {
       console.log(err)
      }
    };
};

// Search trades by status, card name
export const searchTrade = (val) =>{
    return dispatch =>{
        dispatch({type: 'searchTrade', data: val})
    }
  }


  // get user's completed trade count
export const getCompletedTradeCount = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`trade/giftcard/completed/trade`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "TradeCount", data: res.data.completedTrade });
      }
      if(res.status === 400){
        dispatch({ type: "TradeCount_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};

export const createCardTrade = (val) => {
  return async (dispatch, getState) => {
      dispatch({ type: 'TRADE_START' });
    try {
      const res = await PostApi("trade/giftcard/new", {
          cardAmount: val.cardAmount,
          amount: val.amount,
          categoryId: val.categoryId,
          subCategoryId: val.subCategoryId,
          imageUrl: val.imageUrl,
          comment: val.comment
      }, getToken(), "application/json")
      console.log(res)
      if (res.status === 201) {
        dispatch({ type: 'TRADE_SUCCESS' });
        cogoToast.success('Trade created successfully!')
      }
      if(res.status === 400){
        // invalid trade
        dispatch({ type: 'TRADE_ERROR' });
        cogoToast.error('Ooops!, looks like the images uploaded are of a high quality, Kindly optimize and upload again.')
      }
    } catch (err) {
      console.log(err)
      dispatch({ type: 'TRADE_ERROR' });
      cogoToast.error('Ooops!, looks like the images uploaded are of a high quality, Kindly optimize and upload again.')
    }
  };
};


 // clear clearCardTradeSuccess
 export const clearCardTradeSuccess = () =>{
  return dispatch =>{
      dispatch({type: 'clearCardTradeSuccess'})
  }
}
