import React,{useEffect, useRef} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './accounts.module.css'
import { Form, Formik } from 'formik'
import { bankAccountValidator } from "../../../validationSchema/validator";
import bank_icon from '../../../assets/icons/bank.svg'
import delete_icon from '../../../assets/icons/delete_icon.svg'
import { connect } from 'react-redux';
import { addAccountDetails, deleteAccountDetails, getBankAccounts } from '../../../store/actions/auth';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import cogoToast from 'cogo-toast';

const UserAccountsPage = ({accountDetails, getBanks, banks, addAccount, deleteAcct}) => {

    const ref = useRef()

    // submit func
  const handleSubmit = async (values) => {
    console.log(values)
    
    var bankId = values.bankName;
    var bankName = banks.find((pro) => pro.id === parseInt(bankId)).name;

    // get bank code
    var bankCode = banks.find((pro) => pro.id === parseInt(bankId)).code;
    const creds  = {
      ...values,
      bankName: bankName,
      bankCode: bankCode,
    }

    if(accountDetails.length === 2){
        cogoToast.info("You can't add more than two account details!")
    }
    else{
        await addAccount(creds);
        // reset the form
        setTimeout(() => {
            ref.current.reset();
        }, 1000);
    }
   
  }

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  const handleDelete = (id) =>{
      confirmAlert({
        title: "Confirm to submit",
        message: `You are about to remove this account details, Do you wish to continue?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => confirmDelete(id),
          },
          {
            label: "No",
          },
        ],
      });
  }

  const confirmDelete = (id) => {
    deleteAcct(id)
  };


    return ( 
        <>
            <UsersSidebar>
              <p className="titlehead">Accounts</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                     <div className={`mt-5 ${styles.accountCard}`}>
                          <div className={styles.firstHalf}>

                            {
                                accountDetails.length > 0 ? 

                                /* When there is an account added */
                            accountDetails.map((item)=>(
                                    <div className={`mb-4 ${styles.accountDiv}`}>
                                    <div className={styles.accountInfo}>
                                        <div className={styles.accountIcon}>
                                            <img src={bank_icon} alt="bank" className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={`mb-0 ${styles.accountNm}`}>{item.accountName}</h6>
                                            <p className={`mb-0 mt-1 ${styles.accountNo}`}>{item.accountNumber}</p>
                                            <p className={`mb-0 mt-1 ${styles.bankTitle}`}>{item.bankName}</p>
                                        </div>
                                    </div>
                                    <div onClick={() => handleDelete(item.id)} className={styles.deleteIcon}>
                                        <img src={delete_icon} alt="delete" className='img-fluid' />
                                    </div>
                                </div>
                                ))            
                              :

                                /* when there is no account added */
                            <div className={`${styles.noAccount_Div}`}>
                                <div>
                                    <img src={bank_icon} alt="bank" className='img-fluid' />
                                </div>
                                <div>
                                    <h6 className={`mt-3 ${styles.noaccount_title}`}>No Account Added Yet! </h6>
                                    <p className={`mt-2 ${styles.noaccount_subtitle}`}>Add new bank account</p>
                                </div>

                            </div>

                            }
                                                                
        

                           </div>

                           <div className={styles.secondHalf}>
                                 <div>

                                <h6 className={styles.Title}>Add New Account</h6>

                                {/* form submission */}
                                <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                    handleSubmit(values, setSubmitting)
                                }
                                validationSchema={bankAccountValidator}
                                initialValues={{ bankName: "",accountNumber: "", accountName: ""}}
                                >
                                {({
                                    handleChange,
                                    isSubmitting,
                                    handleSubmit,
                                    handleBlur,
                                    values,
                                    touched,
                                    errors,
                                }) => (
                                    <Form ref={ref} onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                        <div className={`mt-3 mb-3 ${styles.input_container}`}>
                                            <label htmlFor='accountNumber'>Account Number</label>
                                            <div>
                                                <input
                                                type="text"
                                                name="accountNumber"
                                                placeholder="Enter your account number"
                                                className={styles.input_style}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.accountNumber}
                                                
                                                />
                                            </div>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.accountNumber && errors.accountNumber}
                                            </small>
                                        </div>

                                        <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor="bankName">Bank</label>
                                            <select
                                                name="bankName"
                                                values={values.bankName}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                defaultValue=""
                                                onBlur={handleBlur}
                                                className={`${styles.input_style}`}
                                                id="bankName"
                                                >
                                                <option value="" disabled>
                                                        Select Bank
                                                </option>
                                                {banks.map((opt, index) => {
                                                    return (
                                                        <option key={index} value={opt.id}>
                                                        {opt.name}
                                                        </option>
                                                    );
                                                })}
                                                        
                                            </select>
                                            <small style={{ color: "#dc3545" }}>
                                                {touched.bankName && errors.bankName}
                                            </small>
                                       
                                        </div>

                                        <div className={`mb-3 ${styles.input_container}`}>
                                            <label htmlFor='accountName'>Account Name</label>
                                            <div>
                                                <input
                                                type="text"
                                                name="accountName"
                                                placeholder="Account Name"
                                                className={styles.input_style}
                                                value={values.accountName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                
                                                />
                                            </div>
                                            <small style={{ color: "#dc3545" }}>
                                             {touched.accountName && errors.accountName}
                                            </small>
                                        </div>

                                     <div className="mt-4">
                                        <button disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_add}`}
                                        >Add Account</button>
                                      </div>


                                     
                                    </div>
                                    </Form>
                                )}
                                </Formik>
                               </div>
                            </div>

                     </div>

                 </div>
             </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
        accountDetails: state.auth.accountDetails,
        banks: state.auth.banks
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        addAccount: (val) => dispatch(addAccountDetails(val)),
        deleteAcct: (id) => dispatch(deleteAccountDetails(id)),
        getBanks: () => dispatch(getBankAccounts()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UserAccountsPage);