import React,{useEffect} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { ResetPinValidator } from '../../../validationSchema/validator'
import { connect } from 'react-redux';
import { clearForgetPinSuccess, ForgetPin } from '../../../store/actions/auth';


const UserResetPin = ({forgetPin, clearForgetPin, forgetpinsuccess}) => {

    const navigate = useNavigate()

         // submit func
  const handleSubmit = async (values) => {
    await forgetPin(values)
  }

  useEffect(() => {
    if (forgetpinsuccess) {
      // reset the form
      setTimeout(() => {
        clearForgetPin();
        navigate("/security/reset-pin/token");
      }, 3000);
    }
  }, [forgetpinsuccess, clearForgetPin, navigate]);

    return ( 
        <>
            <UsersSidebar>
              <p className="titlehead">Security</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.Card}`}>
                    <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                         <div>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you feel your pin is compromised? <br/>
                                Tap to change pin
                             </p>

                             <Link
                                to="/security/change-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Reset Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Can't remember your password? <br/>
                                Tap to reset pin
                             </p>
                             <Link
                                to="/security/reset-pin"
                                 className={`mt-3 btn ${styles.cta_btn} ${styles.cta_btn_active}`}
                                >
                                Reset Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Password</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you want to change your password? <br/>
                             Tap to change password
                             </p>
                             <Link
                                to="/security/change-password"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Password
                            </Link>
                         </div>

                     </div>

                     <div className={`${styles.secondHalf}`}>

                     <h6 className={styles.title}>Reset Pin</h6>

                        <div className={`mt-4`}>
                            {/* form submission */}
                            <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                                }
                                validationSchema={ResetPinValidator}
                                initialValues={{
                                    email: "",
                                    phoneNumber: ""
                                }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                    <div
                                        className={`mt-3 mb-3 ${styles.input_container}`}
                                    >
                                        <label htmlFor="email">
                                        Email Address
                                        </label>
                                        <div>
                                        <input
                                            name="email"
                                            type='email'
                                            placeholder="Enter your email address"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.email &&
                                            errors.email}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <div>
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            placeholder="Enter your phone number"
                                            className={styles.input_style}
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.phoneNumber && errors.phoneNumber}
                                        </small>
                                    </div>

                                 

                                    <div className="mt-4">
                                        <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_submit}`}
                                        >
                                       Next
                                        </button>
                                    </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        </div>

                     </div>

                </div>




                 </div>
             </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      forgetpinsuccess: state.auth.forgetpinsuccess,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      forgetPin: (values) => dispatch(ForgetPin(values)),
      clearForgetPin: () => dispatch(clearForgetPinSuccess()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UserResetPin);