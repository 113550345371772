import React,{useEffect} from 'react'
import OnBoardLeftLayout from '../../components/OnboardingSidebar/sidebar'
import styles from './auth.module.css'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import Button from '../../components/Button/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { resetPasswordValidator } from '../../validationSchema/validator'
import { connect } from 'react-redux'
import { ResetUsersPassword, verifyResetCode } from '../../store/actions/auth'

const ResetPassword = ({Reset, verifyCode, code}) => {

   const search = useLocation().search;

   const navigate = useNavigate()

   // Verify reset code sent to email if valid!
   useEffect(() => {
    const code = new URLSearchParams(search).get("code");
     verifyCode(code);
   }, [verifyCode, search]);


  const handleSubmit = async (values) => {
    const code = new URLSearchParams(search).get("code");
    var creds = {
        code,
        newPassword: values.password,
    };
     await Reset(creds);
     

     setTimeout(()=>{
        navigate('/login')
     },4000)
  }


  return (
    <>
      <div className={styles.Auth_div}>
        <div className="row no-gutters">
          <div className="col-lg-5 d-none d-lg-block">
            <div>
              <OnBoardLeftLayout classname={styles.onboarding_img} />
            </div>
          </div>
          <div className="col-lg-7">
            <div className={styles.auth_right_sect}>

                {
                  code &&
                   <div className={`mt-5 mt-lg-0`}>
                        <p
                            className={styles.resetexpiredtext}
                        >
                            The recovery link you selected has either been used to change
                            your password or is invalid. Re-submit your email address to
                            receive a new recovery link.
                        </p>

                        <div className="mt-5">
                            <p className={`text-center ${styles.login_link}`}>
                                Request another recovery link?{" "}
                                <Link to="/forgotpassword">Back to Forgot Password</Link>
                            </p>
                        </div>
                    </div>
                  }

               {
                  !code &&
                <div>
                  <Formik
                    onSubmit={(values, { setSubmitting }) =>
                      handleSubmit(values, setSubmitting)
                    }
                    validationSchema={resetPasswordValidator}
                    initialValues={{ password: '', confirm_password: "" }}
                  >
                    {({
                      handleChange,
                      isSubmitting,
                      handleSubmit,
                      handleBlur,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form className={styles.form_div}>
                        <div>
                          <h2 className={styles.reset_password}>
                            Reset Password
                          </h2>

                          <p className={`mt-2 ${styles.reset_password_}`}>
                            Kindly keep your password safe
                          </p>
                        </div>
                        <div className={`mt-4 mb-3 ${styles.input_container}`}>
                          <label>New Password</label>
                          <input
                            type="password"
                            name="password"
                            placeholder="**************"
                            className={styles.input_style}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.password && errors.password}
                          </small>
                        </div>

                        <div className={`mb-3 ${styles.input_container}`}>
                          <label>Confirm passsword</label>
                          <input
                            type="password"
                            name="confirm_password"
                            placeholder="**************"
                            className={styles.input_style}
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <small style={{ color: '#dc3545' }}>
                            {touched.confirm_password && errors.confirm_password}
                          </small>
                        </div>

                        <div className="mt-4">
                          {' '}
                          <Button
                            title="Change Password"
                            disabled={isSubmitting}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                }

              <div className={styles.footer}>
                <img src={CBlogo_footer} alt="Logo" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) =>{
  return{
      code: state.auth.resetcode,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    Reset: (creds) => dispatch(ResetUsersPassword(creds)),
    verifyCode: (creds) => dispatch(verifyResetCode(creds)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
