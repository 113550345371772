import React,{useEffect} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './dashboard.module.css'
import arrow_right from '../../../assets/icons/chevron_right.svg'
import earnings from '../../../assets/icons/earnings.svg'
import income_icon from '../../../assets/icons/income_icon.svg'
import withdraw_icon from '../../../assets/icons/withdraw_icon.svg'
import networth from '../../../assets/icons/networth.svg'
import check_circle from '../../../assets/icons/check-circle.svg'
import DataTable from "react-data-table-component";
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTransactions } from '../../../store/actions/wallets';
import Moment from "react-moment";
import { getLatestTrades } from '../../../store/actions/trade';
import { getDashboardAnalytics } from '../../../store/actions/dashboard';


const UsersDashboard = ({fetchTransactions, transactions, loader, fetchLatestTrades, latestTrades, fetchAnalytics,analytics}) => {


    useEffect(()=>{
        let status = 'Pending'
        fetchTransactions(status)
        fetchLatestTrades()
        fetchAnalytics()
      },[fetchTransactions, fetchLatestTrades, fetchAnalytics])

    const navigate = useNavigate()

    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
        },
        {
            name: 'Amount',
            cell: (row) => (
                <span className={styles.recent_table_row}>{`NGN ${row.amount}`}</span>
              ),
        },
        {
            name: 'Date',
            cell: (row) => (
                <span className={styles.recent_table_row}>
                   <Moment format="MMMM Do, YYYY">{row.createdAt}</Moment>
                </span>
              ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <span className={getStatusColor(row.status)}>{`${row.status}`}</span>
            ),
        },
        {
            name: "Action",
            button: true,
            cell: (row) => (
              <button
              className="btn btn-sm btn-view"
              onClick={() => {
                ViewRequest(row._id);
              }}
            >
              View
            </button>
            ),
          },
    ];

    const ViewRequest = (id) =>{
        navigate(`/wallet/details/${id}`)
    }

    const getStatusColor = (val) => {
        let result;
        switch (val) {
          case "Pending":
            result = "pending_icon";
            break;
          case "Successful":
            result = "completed_icon";
            break;
          case "Failed":
            result = "failed_icon";
            break;
          default:
            break;
        }
        return result;
      };

    const numberWithCommas = (x) => { 
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
    }
   

    return ( 
        <>
           <UsersSidebar>
                <p className="titlehead">Dashboard</p>
            </UsersSidebar> 
            <div className="usermain">
                 <div className="contain">

                     <div className='row mt-5'>
                         <div className='col-lg-6 mb-lg-0 mb-5'>

                             <div className={styles.summarytitle_div}>
                                 <h6 className='mb-0'>Wallet Summary</h6>
                                 <img src={arrow_right} width='8' height='8' className='img-fluid' alt="arrow" />
                             </div>

                             <div className='row mt-5'>
                                 <div className='col-lg-6 mb-4 mb-lg-5'>
                                     <div className={styles.walletCard}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.withdrawImgDiv} style={{marginRight: '20px'}}>
                                                 <img src={withdraw_icon} className='img-fluid'  alt='withdraw' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Withdraw</p>
                                                 <h6 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(analytics).length === 0 ? '0.00' : numberWithCommas(analytics.thisMonthWithdrawal)}</h6>
                                                 <p className={`mb-0 mt-1 ${styles.duration}`}>This Month</p>
                                             </div>
                                         </div>
                                     </div>

                                 </div>

                                 <div className='col-lg-6 mb-5 mb-lg-5'>
                                      <div className={styles.walletCard}>
                                         <div className={styles.contentDiv}>
                                             <div  className={styles.incomeImgDiv} style={{marginRight: '20px'}}>
                                                 <img src={income_icon} className='img-fluid' alt='income' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Income</p>
                                                 <h6 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(analytics).length === 0 ? '0.00' : numberWithCommas(analytics.thisMonthIncome)}</h6>
                                                 <p className={`mb-0 mt-1 ${styles.duration}`}>This Month</p>
                                             </div>
                                         </div>

                                     </div>

                                 </div>

                                 <div className='col-lg-6 mb-5 mb-lg-0'>
                                      <div className={styles.noBgCard}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.balanceImg}>
                                                 <img src={networth} className='img-fluid' alt='networth' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Total Balance</p>
                                                 <h5 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(analytics).length === 0 ? '0.00' : numberWithCommas(analytics.walletBalance)}</h5>
                                             </div>
                                         </div>

                                     </div>

                                 </div>

                                 <div className='col-lg-6 mb-lg-0 mb-4'>
                                      <div className={styles.noBgCard}>
                                         <div className={styles.contentDiv}>
                                             <div className={styles.contentImg}>
                                                 <img src={earnings} className='img-fluid' alt='earnings' />
                                             </div>
                                             <div>
                                                 <p className={styles.title}>Total Spent</p>
                                                 <h5 className={`mt-1 mb-0 ${styles.balance}`}>₦{Object.keys(analytics).length === 0 ? '0.00' : numberWithCommas(analytics.totalSpent)}</h5>
                                             </div>
                                         </div>

                                     </div>

                                 </div>

                             </div>


                         </div>

                         <div className='col-lg-6'>

                            <div className={styles.summarytitle_div}>
                                 <h6 className='mb-0'>Latest Trades</h6>
                                 <img src={arrow_right} width='8' height='8' className='img-fluid' alt="arrow" />
                             </div>

                             <div className='mt-5'>

                             {(
                                 latestTrades.length > 0 ? latestTrades.map((item)=>(
                                    <div className={`mb-3 ${styles.latest_div}`}>
                                    <div className={styles.latest_card}>
                                        <div className={styles.latest_cardImg}>
                                            <img src={check_circle} alt="check" className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={`mb-0 ${styles.latestcard_title}`}>{item.subCategoryDetails.categoryname}</h6>
                                            <p className={`mb-0 mt-1 ${styles.latestcard_subtitle}`}>
                                                 <Moment format="MMMM Do, YYYY">{item.createdAt}</Moment>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                    <p className={`mb-0 ${styles.latestcard_amt}`}>USD {item.cardAmount}</p>
                                    </div>
                                </div>
                                 ))
                                 :
                                 <div className='text-center'>
                                        <p>You do not have any trade(s) yet!</p>
                                </div>
                             )}

                           

                              
                             </div>


                        </div>

                     </div>

                     <div className='row mt-4 mt-lg-5'>
                         <div className='col-lg-8 mb-lg-0 mb-4'>
                             <div className={styles.pendingTable}>
                                <DataTable
                                    title="Pending Transactions"
                                    pagination
                                    persistTableHead
                                    columns={columns}
                                    data={transactions}
                                    progressPending={loader}
                                />
                             </div>
                             
                         </div>

                         <div className='col-lg-4'>

                             <div className={styles.tradenow_card}>
                                 <h6 className={`${styles.trade_title}`}>Trade with us</h6>
                                    <p className={`mt-2 ${styles.trade_subtitle}`}>
                                    Trade your gift cards and with ease and at mouth watering rates today. We offer you amazing service that is unbeatable.
                                    </p>
                                 <div className='mt-3'>
                                    <Link to="/start-trade" className={`btn ${styles.btn_trade}`}>Trade Now</Link>
                                 </div>

                             </div>

                         </div>

                     </div>

                </div>
            </div>

        </>
     );
}

const mapStateToProps = (state) => {
    return {
      transactions: state.wallet.transactions,
      loader: state.wallet.loader,
      latestTrades: state.trade.latestTrades,
      analytics: state.dashboard.analytics
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchTransactions: (status) => dispatch(getTransactions(status)),
      fetchLatestTrades: () => dispatch(getLatestTrades()),
      fetchAnalytics : () => dispatch(getDashboardAnalytics()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UsersDashboard);