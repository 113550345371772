import React from 'react'
import styles from './policy.module.css'
import Navbar from '../../components/LandingComponents/Navbar'
import arcImg from '../../assets/images/shapearc.png'
import Footer from '../../components/LandingComponents/Footer'

const PrivacyPage = () => {
  return (
    <>
      <Navbar />
      <div className={styles.policy_section}>
        <div className="contain">
          <div className={styles.titleDiv}>
            <img src={arcImg} alt="arcImg" className="img-fluid" />
            <h3 className={styles.titleP}>Privacy Policy</h3>
          </div>

          <div className={`mt-5 mb-5 ${styles.terms_content}`}>
            <div>
              <h6>INTRODUCTION</h6>
              <p className="mt-3">
                Welcome to CardBarter, Nigeria's best digital exchange service
                provider. We believe that in a world where everyone is over
                exposed, privacy is an absolute prerequisite that everyone is
                entitled to.
              </p>

              <p>
                It would be reckless to request that you sit back and read the
                following paragraphs because this is your privacy, and we value
                it just as much as you do.
              </p>

              <p>
                So pay attention as you read the policy since it will serve as
                your shield while you navigate the digital world.
              </p>

              <p>
                This privacy statement outlines your security rights in terms of
                the data we collect, use, store, share, and protect. It applies
                regardless of how you use our website, services, or tools.
              </p>

              <p>
                The internet, as it is known to everyone, is a global platform
                and storehouse of information where many users encounter issues
                such as hacking, identity theft, cybercrime, and invasion of
                privacy.{' '}
              </p>

              <p>
                At CardBarter, we will preserve your privacy and only use your
                data with your consent in exchange for you trusting us with your
                data despite these risks.
              </p>

              <p>
                CardBarter will keep track of your data in the following ways:
              </p>
            </div>

            <div className="mt-5">
              <h6>INFORMATION YOU PROVIDE AND HOW WE USE THEM</h6>
              <p className="mt-3">
                We ask for basic information like your name, email address, and
                account password when you use any CardBarter service that needs
                registration. We need your debit or credit card details to
                provide services like payment transactions. We normally do not
                store these data to protect you, but when we do, we do so in
                trusted encrypted form on secure servers. We may utilize the
                information you submit as well as information from third parties
                to improve the quality of our services to you. But rest assured,
                these will always be accompanied by an invitation to accept or
                reject the action.
              </p>

              <p>
                Our servers get information that your browser sends
                automatically whenever you visit a website or you do actions on
                CardBarter. Your browser histories, IP address, device details,
                geo-area data, online traffic, reference URL, advertisement
                information, standard web log information, still and moving
                photos are all examples of these automatically obtained data.
              </p>

              <p>
                Note that the personal data we collect about you when you
                register, whether manually, automatically, or via third parties,
                will be kept for the mandatory maintenance time limit set forth
                by applicable law, as well as for us to retain a full record of
                our activities. Regardless of whether your account has been
                successfully authorized, the information necessary from us as
                financial experts will be maintained.
              </p>

              <p>
                We will utilize the personal information we have obtained to:
              </p>

              <div>
                <ol className="mt-3">
                  <li className="mb-2 ml-3">
                    Set up your CardBarter account portfolio, which is used for
                    accounting, billing, legal document management, and claim
                    and dispute resolution.
                  </li>
                  <li className="mb-2 ml-3">
                    Verify your identity in accordance with applicable
                    know-your-customer, anti-money laundering, and other
                    financial laws and regulations, including CardBarter’s
                    anti-money laundering policy and the policies of other law
                    enforcement agencies.
                  </li>
                  <li className="mb-2 ml-3">
                    Analyze how you use the CardBarter website and personalize
                    your service experience to help us improve your customer
                    service requests and support.
                  </li>
                  <li className="mb-2 ml-3">
                    For marketing and promotional purposes, which can also
                    include disclosing data to third parties.
                  </li>
                  <li className="mb-2 ml-3">
                    We may share or transfer your personal information with
                    third parties, including legal and regulatory authorities,
                    contractors, and service providers. All of this will be
                    addressed fully when the time comes.
                  </li>
                  <li className="mb-2 ml-3">
                    Send information, news, promotions, and updates to the email
                    address you gave.
                  </li>
                </ol>
              </div>
              <p>
                All of the actions above can only be carried out if and when you
                have given us your consent or if applicable laws allow us to.
                Without your permission, your data will not be sold,
                transferred, or shared with any third parties. You may easily
                opt-out of receiving promotional information and updates if and
                when you wish.{' '}
              </p>
            </div>

            <div className="mt-5">
              <h6>USE OF COOKIES AND RELATED TECHNOLOGIES</h6>
              <p className="mt-3">
                Cookies are little text files that websites install on your
                device when you visit them. They are commonly employed to ensure
                the seamless operation of websites as well as to collect
                information for the site’s owner.
              </p>
              <p>
                At CardBarter, we use Cookies to assess the viability of our
                site, investigate patterns and traffic, and manage the platform.
                These Cookies collect information that helps us figure out which
                parts of our site are the most visited and how to improve our
                platform for the best user experience. We can improve the nature
                of your platform experience by perceiving and transmitting a
                higher number of the most desired features and services, as well
                as fixing access issues, with this information.
              </p>
              <p>
                We also employ cookies or possibly an invention known as web
                bugs or clear gifs, which are widely inserted in emails to help
                us confirm your receipt of and response to our message, as well
                as to provide you with a more personalized experience when using
                our site.
              </p>
              <p>
                We enlist the assistance of third parties to better understand
                how our site is used. These third parties will place cookies on
                your device’s hard drive and collect information that we need to
                know, such as how visitors like you navigate the site, what
                services are accessed, and general transaction data. We will
                effectively use the data and observations provided by these
                third parties to better understand our users’ interests in our
                site and how to further augment those interests. These third
                parties are legally obligated to use the information collected
                from our site solely for the purposes of our site.
              </p>
            </div>

            <div className="mt-5">
              <h6>AUTOMATED DECISION MAKING </h6>
              <p className="mt-3">
                Based on a credit check, security check, or risk profile, an
                automated decision is made on various topics to determine if we
                can deliver our services to you. Based on the results of the
                investigation, an automatic decision will be taken as to whether
                or not we will make our services available to you.
              </p>
            </div>

            <div className="mt-5">
              <h6>YOUR STATUTORY RIGHTS</h6>
              <p className="mt-3">
                Nigeria's cybercrime law makes data privacy violations illegal.
                This statute mandates that anybody in possession of a person's
                personal data, whether an individual or a service provider, take
                reasonable steps to protect that data against all foreseeable
                risks and breaches, such as theft, cyberattacks, and
                impersonation.{' '}
              </p>
              <p>This means, </p>
              <div>
                <ol className="mt-3">
                  <li className="mb-2 ml-3">
                    You have the right to inquire about the processing of your
                    personal data.
                  </li>
                  <li className="mb-2 ml-3">
                    You have the right to request unrestricted access to your
                    personal information.
                  </li>
                  <li className="mb-2 ml-3">
                    You have the right to request that any inaccurate or
                    incomplete personal data we have about you be corrected.
                  </li>
                  <li className="mb-2 ml-3">
                    You have the right to request that we delete or erase
                    personal information from our database in certain
                    circumstances where there are no legal exceptions.
                  </li>
                  <li className="mb-2 ml-3">
                    You have the right to limit or object to the use of your
                    personal information in certain circumstances.
                  </li>
                  <li className="mb-2 ml-3">
                    You have the right to object to and have any automated
                    decision made about you that has legal consequences
                    reconsidered.
                  </li>
                </ol>
              </div>
            </div>

            <div className="mt-5">
              <h6>SECURITY OF YOUR DATA </h6>
              <p className="mt-3">
                We utilize a variety of trustworthy security methods to protect
                the confidentiality of your personal data and to protect it from
                threats and breaches, unauthorized access, alteration, misuse,
                mismanagement, or destruction. Password-protected directories
                and databases, Secure Sockets Layered (SSL) technology to ensure
                that your information is fully encoded and transferred across
                the internet securely, and active PCI scanning to protect our
                servers from hackers and other vulnerabilities are just a few of
                the security measures in place.
              </p>
            </div>

            <div className="mt-5">
              <h6>INFORMATION WE WON'T REQUEST FROM YOU</h6>
              <p className="mt-3">
                In an email, text, or any other kind of communication with you,
                CardBarter will not ask for payment details such as your ATM
                card number, account or pin number. As a customer, you are
                responsible for keeping your password and membership information
                secure. Under no circumstances should those details be shared
                with anyone. Notify us right away if you get a questionable
                request or if your personal information is being misused.
              </p>
            </div>

            <div className="mt-5">
              <h6>UPDATES TO THIS PRIVACY POLICYCOPYRIGHT POLICY </h6>
              <p className="mt-3">
                This privacy statement was last updated in May of 2022. We
                recommend that you review this policy on a regular basis because
                it is subject to modification. Users will be notified of any
                changes to this privacy policy via the website or other means of
                communication.
              </p>
            </div>

            <div className="mt-5">
              <h6>Contact Us </h6>
              <p className="mt-3">
                If there are questions, comments, observations or concerns
                regarding our services, terms, conditions and privacy policy,
                please contact us via{' '}
                <a href='mailto:support@cardbarter.com'>support@cardbarter.com</a>.
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPage
