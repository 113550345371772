import React, { useEffect, useState } from 'react'
import UsersSidebar from '../../../components/UserSidebar/sidebar'
import styles from './withdraw.module.css'
import { Link, useNavigate } from 'react-router-dom'
import bankIcon from '../../../assets/icons/bank 1.svg'
import { Form, Formik } from 'formik'
import { withdrawalValidator } from '../../../validationSchema/validator'
import bank_icon from '../../../assets/icons/bank.svg'
import { connect } from 'react-redux'
import { clearWithdrawal, getWalletBalance, WithdrawFunds } from '../../../store/actions/wallets'
import cogoToast from 'cogo-toast'

const UserWithdrawPage = ({handleWithdraw, accountDetails, fetchWalletBal, balance, withdrawsuccess,clearWithdrawSuccess}) => {

  const navigate = useNavigate()

  useEffect(()=>{
    fetchWalletBal()
  },[fetchWalletBal])

  const [selected, setSelected] = useState(false)
  const [bankInfo, setBankInfo] = useState({});

  const onValueChange = (id) =>{
    setSelected(true)
    let val = accountDetails.find((pro) => pro.id === id);
    setBankInfo(val);
  }

  // submit func
  const handleSubmit = async (values) => {
    if(selected){
       console.log(values);
        const creds = {
          ...values,
          accountName: bankInfo.accountName,
          accountNumber: bankInfo.accountNumber,
          bankCode: bankInfo.bankCode,
          bankName: bankInfo.bankName,
          amount: parseFloat(values.amount),
          pin: parseInt(values.pin),
        }
        console.log(creds)
        await handleWithdraw(creds)
    }
    else{
      cogoToast.info('Please select from the account details listed your preferred bank')
    }
  }

  const numberWithCommas = (x) => { 
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
   }

   useEffect(()=>{
    if(withdrawsuccess){
        setTimeout(()=>{
            navigate('/wallets')
            clearWithdrawSuccess()
        },2000)    
    }
},[withdrawsuccess,navigate, clearWithdrawSuccess])


  return (
    <>
      <UsersSidebar>
        <p className="titlehead">Withdraw</p>
      </UsersSidebar>
      <div className="usermain">
        <div className="contain">
          {accountDetails.length === 0 ? (
            <div className={`mt-5 ${styles.emptyWalletCard}`}>
              <div>
                <div className={styles.bank_icon}>
                  <img src={bankIcon} alt="Bank Icon" />
                </div>
                <h6 className={`mt-3 ${styles.no_widthdrawal}`}>
                  No bank account added yet!{' '}
                </h6>
                <p className={`mt-2 ${styles.add_new_acct_p}`}>
                  <Link to="/accounts" className={styles.add_new_acct_link}>
                    Add new bank account
                  </Link>
                </p>
              </div>
            </div>
          ) : (
            <div className={`mt-5 ${styles.accountCard}`}>
              <div className={styles.firstHalf}>
               
                <h6 className={`mb-0 ${styles.cardTitle}`}>Select account to send money</h6>
                {/* When there is an account added */}
                {accountDetails.map((item)=>(  
                   <div className={`mt-4 mb-4 ${styles.account_and_radio_div}`}>
                   <div className={styles.options_div}>
                     <div className={styles.options}>
                       <label title="account1">
                         <input type="radio"
                           onChange={() => onValueChange(item.id)}
                          name="foo" value="accont_one" />
                         <div />
                       </label>
                     </div>
                   </div>
                   <div className={styles.main_accountDiv}>
                     <div className={`${styles.accountDiv}`}>
                       <div className={styles.accountInfo}>
                         <div className={`d-none d-lg-flex ${styles.accountIcon}`}>
                           <img
                             src={bank_icon}
                             alt="bank"
                             className="img-fluid"
                           />
                         </div>
                         <div>
                           <h6 className={`mb-0 ${styles.accountNm}`}>
                             {item.accountName}
                           </h6>
                           <p className={`mb-0 mt-1 ${styles.accountNo}`}>
                             {item.accountNumber}
                           </p>
                            <p className={`mb-0 mt-1 ${styles.bankTitle}`}>
                             {item.bankName}
                           </p>
                         </div>
                       </div>
                     </div>
                   </div>
                   </div>
                ))}

              </div>

              <div className={styles.secondHalf}>
                <div className={styles.wallet_balance_div}>
                  <h6 className={styles.wallet_balance}>
                    Wallet Balance: <span>₦{ balance ? numberWithCommas(balance) : '0.00'}</span>
                  </h6>
                </div>
                <div className={`mb-0 mt-5`}>
                  {/* form submission */}
                  <Formik
                    onSubmit={(values, { setSubmitting }) =>
                      handleSubmit(values, setSubmitting)
                    }
                    validationSchema={withdrawalValidator}
                    initialValues={{
                      amount: '',
                      narration: '',
                      pin: '',
                    }}
                  >
                    {({
                      handleChange,
                      isSubmitting,
                      handleSubmit,
                      handleBlur,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className={styles.form_div}>
                          <div
                            className={`mt-3 mb-3 ${styles.input_container}`}
                          >
                            <label htmlFor="amount">
                              Withdrawal Amount
                            </label>
                            <div>
                              <input
                                name="amount"
                                placeholder="Amount"
                                className={styles.input_style}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.amount}
                              />
                            </div>
                            <small style={{ color: '#dc3545' }}>
                              {touched.amount &&
                                errors.amount}
                            </small>
                          </div>

                          <div
                            className={`mt-3 mb-3 ${styles.input_container}`}
                          >
                            <label htmlFor="narration">Narration</label>
                            <div>
                              <textarea
                                type="text"
                                name="narration"
                                placeholder="Enter a narration"
                                className={styles.input_style}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.narration}
                                rows="6"
                                style={{resize: 'none'}}
                              />
                            </div>
                            <small style={{ color: '#dc3545' }}>
                              {touched.narration && errors.narration}
                            </small>
                          </div>

                          <div className={`mb-3 ${styles.input_container}`}>
                            <label htmlFor="pin">Transaction Pin</label>
                            <div>
                              <input
                                type="password"
                                name="pin"
                                placeholder="Transaction Pin"
                                className={styles.input_style}
                                value={values.pin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <small style={{ color: '#dc3545' }}>
                              {touched.pin && errors.pin}
                            </small>
                          </div>

                          <div className="mt-4">
                            <button
                              disabled={isSubmitting}
                              type="submit"
                              className={`btn ${styles.btn_add}`}
                            >
                              Withdraw
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    accountDetails: state.auth.accountDetails,
     balance: state.wallet.WalletBalance,
     withdrawsuccess: state.wallet.withdrawsuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleWithdraw: (values) => dispatch(WithdrawFunds(values)),
    fetchWalletBal: () => dispatch(getWalletBalance()),
    clearWithdrawSuccess: () => dispatch(clearWithdrawal()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserWithdrawPage)
