import React from 'react'
import onboardingimg from '../../assets/images/onboardingimg.png'

const OnBoardLeftLayout = ({ classname }) => {
  return (
    <>
      <img src={onboardingimg} alt="Onboarding pic" className={classname} />
    </>
  )
}

export default OnBoardLeftLayout
