import React from 'react'
import styles from './policy.module.css'
import Navbar from '../../components/LandingComponents/Navbar'
import arcImg from '../../assets/images/shapearc.png'
import Footer from '../../components/LandingComponents/Footer'

const TermsPage = () => {
  return (
    <>
      <Navbar />
      <div className={styles.policy_section}>
        <div className="contain">
          <div className={styles.titleDiv}>
            <img src={arcImg} alt="arcImg" className="img-fluid" />
            <h3 className={styles.title}>Terms and Conditions</h3>
          </div>

          <div className={`mt-5 mb-5 ${styles.terms_content}`}>
            <div>
              <h6>INTRODUCTION</h6>
              <p className="mt-3">
                This is CardBarter (‘Us’, ‘We’ as used here), Nigeria's best
                digital exchange service and User-friendly platform. You've just
                selected our terms and conditions of use. Please take your time
                and study the following pages carefully to understand the
                services we provide.
              </p>

              <p>
                The usage of our websites, Cardbarter.ng, and the services
                provided by CardBarter are governed by these terms and
                conditions. You will find information about our important
                commercial terms, duties, rights, and responsibilities, as well
                as how we collect, store, utilize, and secure information
                derived from your use of our websites and services, in these
                terms and conditions. It is also critical that you read our
                privacy policy carefully. This can be found here: (insert a link
                to the privacy policy page). Please keep in mind that these
                terms and conditions apply to all users and anybody who seek to
                access or use our website and services.
              </p>

              <p>
                When you sign our agreement form, you confirm that you have read
                and understood our rules and that you agree to be bound by them.
                If you are unable to comply with these agreements for any
                reason, you may not utilize our services. Please contact us at
                (insert email address) so that we can fix or clarify any issues.
              </p>

              <p>Thank you for thinking of us.</p>
            </div>

            <div className="mt-5">
              <h6>REGISTRATION</h6>
              <p className="mt-3">
                You consent to receive newsletters, marketing and promotional
                materials, and other updates from us by creating an account with
                us. You can still opt out of any or all of these by simply
                unsubscribing or emailing us at (insert email address).
              </p>
            </div>

            <div className="mt-5">
              <h6>CONTENT</h6>
              <p className="mt-3">
                CardBarter owns the information found on or via our services, or
                it has been legitimately used with consent. Without submitting
                us an extensive prior written letter of authorization, you may
                not share, alter, transfer, reuse, download, repost, reproduce,
                or use such contents, in whole or in part, for personal or
                commercial gain.
              </p>
              <p>
                Our trademarks, logo, or brand name may not be used in
                connection with any product or service, whether owned by us or a
                third party, without first obtaining CardBarter’s permission.
              </p>
            </div>

            <div className="mt-5">
              <h6>TRANSACTIONS</h6>
              <p className="mt-3">
                If you are granted value in excess for a transaction during a
                transaction between CardBarter and you, you agree to be held
                liable and to have a hold placed on your account until the
                mistake is corrected.
              </p>
            </div>

            <div className="mt-5">
              <h6>ANALYTICS</h6>
              <div>
                <ol className="mt-3">
                  <li className="mb-2 ml-3">
                    <strong>Ours</strong>: To observe and analyze how our
                    services are used, we may use third-party service providers.
                    Only trusted and tried third-party service providers are
                    used in this process.
                  </li>
                  <li className="mb-2 ml-3">
                    <strong>Google's</strong>: To administer and analyze the use
                    of our services, Google collects data from users' browsing
                    histories. Google provides this function, which is known as
                    web analytics. Google uses the information gathered from
                    this activity to personalize and contextualize its own
                    advertising operations, and it may also be shared with other
                    Google services.
                  </li>
                </ol>
              </div>
            </div>

            <div className="mt-5">
              <h6>YOUR ACTIONS </h6>
              <p className="mt-3">
                You may only use our services in line with the law and our
                policies. You may not:{' '}
              </p>
              <div>
                <ol className="mt-3">
                  <li className="mb-2 ml-3">
                    Use our services in any way that infringes on the rights of
                    others or violates any local, national, or international law
                    or regulation by agreeing to our terms and conditions of
                    service.
                  </li>
                  <li className="mb-2 ml-3">
                    Use our services to impersonate or try to impersonate Us,
                    our staff, another user, or any other person.
                  </li>
                  <li className="mb-2 ml-3">
                    Send, transfer, or share any of our marketing or promotional
                    materials, including “junk mail,” “chain letters,” “spam,”
                    or other similar updates.
                  </li>
                  <li className="mb-2 ml-3">
                    Use Service in any way that could cripple, overburden,
                    impair, or hinder Service, or interfere with the ability of
                    any third person to participate in real-time actions through
                    Service.
                  </li>
                  <li className="mb-2 ml-3">
                    Without our prior written approval, use any manual processes
                    (robots, spiders, or other Web crawling technologies) to
                    screen or copy any of the material on the Service or for any
                    other unapproved cause.
                  </li>

                  <li className="mb-2 ml-3">
                    Take any action that could harm or degrade the Company’s
                    rating.
                  </li>
                  <li className="mb-2 ml-3">
                    Assault Service by an appropriated refusal of administration
                    assault or a refusal of service assault.
                  </li>
                  <li className="mb-2 ml-3">
                    Attempt to gain unauthorized access to, interfere with,
                    harm, or disrupt any Service stored on or connected to our
                    database.
                  </li>
                  <li className="mb-2 ml-3">
                    Use any device, software programming, or acts that interfere
                    with Service’s smooth operation.
                  </li>

                  <li className="mb-2 ml-3">
                    Display any harmful or mechanically destructive viruses,
                    diversions, worms, logic bombs, or other material.
                  </li>
                  <li className="mb-2 ml-3">
                    Use any device, software programming, or acts that interfere
                    with Service’s smooth operation.
                  </li>

                  <li className="mb-2 ml-3">
                    Display any harmful or mechanically destructive viruses,
                    diversions, worms, logic bombs, or other materials.
                  </li>
                </ol>
              </div>
            </div>

            <div className="mt-5">
              <h6>MEMBERSHIP</h6>
              <p className="mt-3">
                When you register with us, you confirm that you are at least 18
                years old and that the information you provide is accurate,
                complete, and up to date at all times. Wrong, insufficient, or
                out-of-date data may result in the termination of your Service
                account.
              </p>

              <p>
                You may not use the name of another person as a username, or a
                name that isn’t legally accessible for use, or a name or brand
                name that is subject to any third-party rights or is legally
                linked to another without appropriate clearance. Any name that
                is unfriendly, disgusting, or vulgar may not be used as a
                username.
              </p>

              <p>
                Maintaining the security of your portfolio, pin, password, and
                two-factor authentication service, as well as keeping your
                gadgets and/or information from being stolen.
              </p>

              <p>
                At the time of account activation, you are responsible for
                maintaining the security of your portfolio, pin, password, and
                two-factor authentication service, as well as protecting your
                gadgets and/or account from falling into the wrong hands. Notify
                us right away if there has been a security breach or illegal use
                of your account.
              </p>
              <p>
                In our sole discretion, we have the right to refuse services,
                terminate records, delete or edit content, or cancel
                transactions.{' '}
              </p>
            </div>

            <div className="mt-5">
              <h6>COPYRIGHT POLICY </h6>
              <p className="mt-3">
                CardBarter adheres to the legal idea that gives the creator of
                an original work exclusive rights to use the content for a set
                period of time, preventing others from plagiarizing it.
              </p>

              <p>
                If you believe that copyrighted content has been copied or used
                in a way that constitutes copyright infringement, please submit
                your claim via email to (insert email address) with the subject
                line “Copyright Infringement” and a detailed description of the
                alleged Infringement under “DMCA Notice and Procedure for
                Copyright Infringement Claims.”
              </p>

              <p>
                You may be held liable for damages and bad-faith claims if your
                claim is found to be untrue and/or misleading after an inquiry.
                This will be significant.
              </p>
            </div>

            <div className="mt-5">
              <h6>FEEDBACK AND ERROR REPORTING </h6>
              <p className="mt-3">
                CardBarter has a team available 24 hours a day, 7 days a week to
                respond to users’ questions, problems, recommendations for
                changes, observations, ideas, issues, and any concerns about the
                services we provide. You can reach out to us directly at (insert
                email address).
              </p>
            </div>

            <div className="mt-5">
              <h6>THIRD PARTY </h6>
              <p className="mt-3">
                You may come across links to third-party websites while using
                our services that are not known or controlled by CardBarter
                Nigeria Limited. We disclaim any and all liability for the
                content, policies, or actions of third-party websites or
                services.
              </p>

              <p>
                By accepting our terms of service, you agree that CardBarter
                Nigeria limited will not be held liable, directly or indirectly,
                for any damage or loss caused or allegedly caused by or in
                connection with the use of or reliance on such third-party
                services.
              </p>

              <p>
                We strongly suggest you to read the terms of service and privacy
                policies of any third-party websites you visit before
                proceeding.
              </p>
            </div>

            <div className="mt-5">
              <h6>DISCLAIMER OF WARRANTY </h6>
              <p className="mt-3">
                CardBarter’s services are provided on a “as is” and “as
                available” basis. CardBarter offers no express or implied
                representations or warranties regarding the use of its services
                and contents. Any services or materials obtained from us are
                solely at your risk.
              </p>
            </div>

            <div className="mt-5">
              <h6>TERMINATION OF ACCOUNT </h6>
              <p className="mt-3">
                If you choose to terminate your membership due to an issue that
                we or you alone are aware of, you can do so by simply ceasing to
                utilize our service.
              </p>
              <p>
                CardBarter may suspend, cancel, or limit a user’s access to
                service at any time for any reason, including but not limited to
                a breach of conditions, with or without prior notice.
              </p>
            </div>

            <div className="mt-5">
              <h6>GOVERNING LAW </h6>
              <p className="mt-3">
                These Terms will be governed by and construed in accordance with
                the laws of the Federal Republic of Nigeria, excluding its
                conflict of law provisions.
              </p>
              <p>
                If a court rules that any provision of these terms of service is
                invalid or unenforceable, the remaining elements of these terms
                of service will continue to apply.
              </p>
            </div>

            <div className="mt-5">
              <h6>CHANGES TO SERVICE </h6>
              <p className="mt-3">
                We reserve the right to terminate or modify our Service, as well
                as any assistance or material we provide through it, at any time
                and without warning. We will not be held liable if all or any
                part of the Service is unavailable at any time or for any
                duration. We reserve the right to restrict access to specific
                parts of the Service, or the entire Service, to users, including
                registered users, from time to time.
              </p>
            </div>

            <div className="mt-5">
              <h6>AMMENDMENT TO TERMS </h6>
              <p className="mt-3">
                We reserve the right to change the Terms at any moment by
                posting the new terms on this website. It is your duty to check
                these Terms on a regular basis.
              </p>
              <p>
                We reserve the right to change the Terms at any moment by
                posting the new terms on this website. It is your duty to check
                these Terms on a regular basis.
              </p>
              <p>
                You agree to be bound by the amended terms if you continue to
                access or use our Service after any adjustments become
                effective. You are no longer authorized to use Service if you do
                not agree to the revised conditions.
              </p>
            </div>

            <div className="mt-5">
              <h6>ACKNOWLEDGEMENT </h6>
              <p className="mt-3">
                You acknowledge that you have read and agree to be bound by
                these terms of service by using our service or anything provided
                by us.
              </p>
            </div>

            <div className="mt-5">
              <h6>Contact Us </h6>
              <p className="mt-3">
                For enquiries, comments, requests, observations on our services
                and/or technical support, contact us via <a href='mailto:support@cardbarter.com'>support@cardbarter.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsPage
