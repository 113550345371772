import React from 'react';
import styles from './landing.module.css'
import { HashLink as Link } from "react-router-hash-link";
import Logo from '../../assets/icons/CBLogoWhite.svg'
import facebook from '../../assets/icons/facebook.svg'
import twitter from '../../assets/icons/twitter.svg'
import instagram from '../../assets/icons/instagram.svg'

const Footer = () => {
    return ( 
        <>
             <div className={styles.section_footer}>
                <div className='contain'>

                <div className='row'>
                    <div className='col-lg-5'>

                        <div>
                        <img src={Logo} alt="logo" className='img-fluid' />
                        </div>

                        <div className='mt-lg-4 mt-3'>
                            <p className={styles.footer_info}>
                            We are CardBarter, a highly innovative and 
                                experienced giftcard trading company.
                            </p>
                        </div>

                        <div className={styles.social_icons}>
                                <div className={`${styles.social_div}`}>
                                    <a href="https://instagram.com/cardbarter.ng" target="_blank" rel='noreferrer'>
                                        <img src={instagram} className="img-fluid" alt="instagram Logo" />
                                    </a>
                                </div>  
                                <div className={`${styles.social_div}`}>
                                    <a href="https://web.facebook.com/cardbarter.ng" target="_blank" rel='noreferrer'>
                                        <img src={facebook} className="img-fluid" alt="facebook Logo" />
                                    </a>
                                </div>  
                                <div className={`${styles.social_div}`}>
                                    <a href="https://twitter.com/cardbarterng" target="_blank" rel='noreferrer'>
                                        <img src={twitter} className="img-fluid" alt="twitter Logo" />
                                    </a>
                                </div>        
                        </div>

                     </div>

                     <div className='col-lg-7'>
                         <div className='row'>
                        

                            <div className='col-lg-6'>
                                <h6 className={`mt-4 mt-lg-3 ${styles.footer_link_title}`}>
                                Our Links
                                    </h6>
                                    <ul className={`mt-3 mt-lg-4 ${styles.footer_links}`}>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className='mt-2'>
                                            <Link to="/terms">Terms and Conditions</Link>
                                        </li>
                                        <li className='mt-2'>
                                            <Link to="/privacy">Privacy Policy</Link>
                                        </li>
                                        <li className='mt-2'>
                                            <Link to="/amlpolicy">AML Policy</Link>
                                        </li>
                                        <li className='mt-2'>
                                            <Link to="/ratecalculator">Rate Calculator</Link>
                                        </li>
                                </ul>
                            </div>

                            <div className='col-lg-6'>
                                <h6 className={`mt-4 mt-lg-3 ${styles.footer_link_title}`}>
                                        Contact Us
                                    </h6>
                                    <ul className={`mt-3 mt-lg-4 ${styles.footer_links}`}>
                                        <li>
                                            <a href='tel:2349040337331' className={styles.contact}>+2349040337331</a>
                                        </li>
                                        <li className='mt-2'>
                                            <a href='mailto:info@Cardbarter.com' className={styles.contact}>support@cardbarter.ng</a>
                                        </li>
                                        <li className='mt-2'>
                                            <a 
                                             target="_blank"
                                             rel='noreferrer'
                                             className={styles.contact}
                                             href="https://api.whatsapp.com/send?phone=+234 904 033 7331&amp;text=Hello CardBarter, I want to trade with you"
                                            >Whatsapp</a>
                                        </li>
                                </ul>
                            </div>

                         </div>
                         
                     </div>
                    
                </div>

                <hr className={styles.footer_hr} />

                <div className='text-center mt-5'>
                         <p className={`mb-0 ${styles.footer_copyright}`}>
                            Copyright &copy; CardBarter {new Date().getFullYear()} 
                        </p>
                </div>



                </div>
            </div>
        </>
     );
}
 
export default Footer;