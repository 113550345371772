import React from 'react'
import notfound_img from '../../assets/images/not-found.svg'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import styles from '../NotFound/NotFound.module.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Link } from 'react-router-dom'

const NotFound = () => {

  return (
    <>
      <div className={styles.NotFound_div}>
        <div className={styles.NotFound_img_div}>
          <img
            src={notfound_img}
            alt="Not Found"
            className={styles.NotFound_img}
          />
          <p className={styles.NotFound_warning}>
            Sorry, even our explorers could not find the page you are lookin
            for!
          </p>
          <div className={styles.NotFound_homepage}>
            <p>They suggest you look at the Homepage</p>
          </div>
          <Link to="/" className={styles.back2home_div}>
            <ChevronLeftIcon className={styles.chevron_left_icon} />
            <p className={styles.back2home}>Back to Home</p>
          </Link>
        </div>
        <div className={styles.footer}>
          <img src={CBlogo_footer} alt="Logo" className='img-fluid' />
        </div>
      </div>
    </>
  )
}

export default NotFound
