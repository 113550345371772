import React from 'react';
import styles from './landing.module.css'
import box from '../../assets/icons/box-search.svg'
import cpu from '../../assets/icons/cpu.svg'
import zap from '../../assets/icons/zap.svg'
import chart from '../../assets/icons/chart-square.svg'

const FeaturesSection = () => {
    return ( 
        <>
            <div className={styles.features_sect}>
                <div className='contain'>

                    <div className='text-center'>
                        <h2 className={styles.feat_title}>
                             Why trade with us?
                        </h2>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-lg-6 mb-5'>
                            <div className={styles.features_div}>
                                <div className={styles.features_icon_div}>
                                    <img src={box} className='img-fluid' alt='box' />
                                </div>
                                <div>
                                    <h6 className={styles.features_title}>
                                       Fast and reliable
                                    </h6>
                                    <p className={`mb-0 ${styles.features_subtext}`}>
                                    With processing time that's typically only minutes, payout is instant!  We take pride in our reputation and believe that nothing is more important to us than ensuring that our traders are satisfied with their interactions.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-6 mb-5'>
                             <div className={styles.features_div}>
                                <div className={styles.features_icon_div}>
                                    <img src={cpu} className='img-fluid' alt='cpu' />
                                </div>
                                <div>
                                    <h6 className={styles.features_title}>
                                    Unbeatable rates
                                    </h6>
                                    <p className={`mb-0 ${styles.features_subtext}`}>
                                    Our rates are unbeatable, and we will make sure you get the best deal possible. If you're looking for the best gift card rates around, look no further than Cardbarter! We have the best deals on the market.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-6 mb-5 mb-lg-0'>
                             <div className={styles.features_div}>
                                <div className={styles.features_icon_div}>
                                    <img src={zap} className='img-fluid' alt='zap' />
                                </div>
                                <div>
                                    <h6 className={styles.features_title}>
                                    Support a wide range of gift cards
                                    </h6>
                                    <p className={`mb-0 ${styles.features_subtext}`}>
                                    Here are some of gift cards we accept; <br/>
                                    iTunes gift card,
                                    AMEX gift card,
                                    Sephora gift card,
                                    Nordstrom gift card,
                                    Macy’s gift card,
                                    eBay gift card,
                                    NIKE gift card,
                                    Steam gift card,
                                    One Vanilla gift card,
                                    Footlocker gift card
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-6'>
                             <div className={styles.features_div}>
                                <div className={styles.features_icon_div}>
                                    <img src={chart} className='img-fluid' alt='chart' />
                                </div>
                                <div>
                                    <h6 className={styles.features_title}>
                                    24/7 Customer support
                                    </h6>
                                    <p className={`mb-0 ${styles.features_subtext}`}>
                                    Whether you’re looking for help with a simple question or something more complex, our team is here to assist. So don’t hesitate to reach out – we would be more than happy to help!
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
     );
}
 
export default FeaturesSection;