import React,{useState} from 'react';
import Navbar from '../../components/LandingComponents/Navbar';
import styles from './faq.module.css'
import faqImg from '../../assets/images/shapearc.png'
import arrowDown from '../../assets/icons/arrowDown.svg'
import arrowUp from '../../assets/icons/arrowUp.svg'
import Footer from '../../components/LandingComponents/Footer';

const FAQPage = () => {

    const [val, setVal] = useState(null);

    const toggleCard = (id) => {
       setVal(id === val ? null : id)      
     };
  
     const FAQS = [
       {
         id: 1,
         title: "How do I trade on Cardbarter?",
         content: () => (
           <>
           <ul className='ml-3'>
             <li>
             Register by providing your name, email address, phone number and password.
             </li>
             <li>
               You would have to verify your account before proceeding.
             </li>
             <li>
             On your dashboard, set your withdrawal pin. Click ‘accounts’ and add your banking details.
             </li>
             <li>
             Click ‘start a trade’ and select the type of gift card you’d like to trade.
             </li>
             <li>
              Upload an image of the gift card and choose the appropriate gift card category.
             </li>
             <li>
             Input the denomination and click ‘start trade’
             </li>
             <li>
             As soon as your trade is confirmed, you’d get credited in your wallet instantly. Afterwards, you can proceed to withdraw to your bank account.
             </li>
           </ul>
          
           </>
         ),
       },
       {
         id: 2,
         title: "What gift cards can I sell?",
         content: () => <>
         Gift cards supported on Cardbarter include;
          Amazon gift card, American Express (AMEX) gift card, Apple Store gift card, Best buy gift card, eBay gift card, Footlocker gift card, Gamestop gift card, Google play gift card, iTunes gift card, Macy’s gift card, NIKE gift card, Nordstrom gift card, Offgamers gift card, Walmart gift card, Sephora gift card and many others.
         </>
       },
       {
         id: 3,
         title: "Do you trade on Whatsapp?",
         content: () => (
           <>
           We do not trade on Whatsapp. Our WhatsApp contact (+2349040337331) is only for customer support.
           </>
         ),
       },
       {
         id: 4,
         title: "How do I withdraw from my wallet?",
         content: () => <>
         On your dashboard, click ‘withdraw funds’.  Input the amount you'd like to cash out, and your transaction PIN. Proceed to withdraw. 
         </>
       }, 
       {
        id: 5,
        title: "How long do I have to wait to get my naira withdrawal?",
        content: () => <>
        Withdrawals from your Cardbarter wallet are automatically processed. Typically, you will have your funds in your bank account within 5 minutes. However, banks occasionally experience complications that could result in a delay in payment and which is beyond our control. Please be patient and reach out to us if you do not receive your payment in the next 24 hours.
        </>
       }, 
       {
        id: 6,
        title: "Why Is My Trade Rejected?",
        content: () => <>
         There are a set of reasons why your trade might be rejected;
        <ul className='ml-3 mb-1'>
          <li>
          You submitted a blank trade
          </li>
          <li>
          Your gift card isn't clear enough
          </li>
          <li>
          Your gift card has already been used
          </li>
          <li>
          Your gift card code is incorrect
          </li>
          <li>
          Your gift card isn't activated yet, and so on.
          </li>
        </ul>
        Regardless, the cause will be clearly stated, along with the rejection.
        </>
      },    
     ];

    return ( 
        <>
            <Navbar />
            <div className={styles.faq_section}>
                <div className='contain'>

                    <div className={styles.titleDiv}>
                        <img src={faqImg} alt="faqbg" className='img-fluid' />
                        <h3 className={styles.title}>Frequently Asked Questions</h3>
                    </div>

                    {/* Frequently asked questions section */}
                    <div className={`mt-5 ${styles.faq_card}`}>
                            {FAQS.map(({ id, title, content }) => (
                                <div key={id}
                                onClick={() => toggleCard(id)}
                                className={`mb-3 mb-md-3 ${styles.card}`}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <p className={styles.faq_title}>
                                        {title}
                                        </p>
                                        <img 
                                         src={val === id ? arrowDown : arrowUp}
                                         alt="arrow" style={{cursor: 'pointer'}} width='30' height='30' className='img-fluid' />      
                                    </div>
        
                                    <div className={
                                            val === id
                                            ? "d-block mt-4"
                                            : "d-none mt-4"
                                        }
                                        >
                                        <p className={styles.faq_content}>
                                            {content()}
                                        </p>
                                    </div>
                                </div>    
                            ))}
                        
                    </div>



                </div>

            </div>
            <Footer/>
        </>
     );
}
 
export default FAQPage;