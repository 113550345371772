import React,{useState, useEffect} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './notifications.module.css'
import PaginationComponent from '../../../components/Pagination/pagination';
import { connect } from 'react-redux';
import { getNotifications } from '../../../store/actions/notifications';
import Moment from "react-moment";


const UserNotificationsPage = ({fetchNotifications, notifications, allcount}) => {

    let PageSize = 6

    useEffect(()=>{
        fetchNotifications(6, 1)
    },[fetchNotifications])

    const [currentPage, setCurrentPage] = useState(1);

    const getMoreItemsByOffset = (page) => {
        setCurrentPage(page)
        fetchNotifications(6, page)
    }

    return ( 
        <>
            <UsersSidebar>
                <p className="titlehead">Notifications</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">
                    <div className={`mt-5 ${styles.Card}`}>

                        <div className='row'>
                         {(
                            notifications.length > 0 ? notifications.map((item)=>(     
                                <div className='col-lg-6 mb-4'>
                                <div className={styles.notify_card}>
                                    <div className={styles.notify_title_div}>
                                            <p className={styles.title}>
                                            {item.title}</p>
                                            <p className={styles.date}>
                                            <Moment format="MMMM Do, YYYY, h:mm:ss a">
                                                {item.createdAt}
                                                </Moment>           
                                                </p>
                                        </div>
                                        <div className='mt-4'>
                                            <p className={styles.content}>
                                            {item.message}
                                            </p> 
                                        </div>
                                </div>
                            </div>
                                    
                        ))
                        :
                         ""
                        )
                        }
                        </div>

                            {
                            notifications.length === 0 &&
                            <div className={`${styles.no_notifications}`}>
                                <p>There are no notifications available yet!</p>
                            </div>
                         }

                       
                    {
                         notifications.length > 0 &&
                         <div className={styles.pagination_div}>
                             <PaginationComponent
                                 currentPage={currentPage}
                                 totalCount={allcount}
                                 pageSize={PageSize}
                                 onPageChange={(page) => getMoreItemsByOffset(page)}
                             />
                         </div>
                    }
                      

                    </div>
                  </div>
            </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      notifications: state.notification.notifications,
      allcount: state.notification.allCount
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchNotifications: (limit, offset) => dispatch(getNotifications(limit, offset)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsPage);