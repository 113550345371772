const initState = {
    analytics: {},
 
  };
  
  const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case 'Analytics':
          return{
            ...state,
            analytics: action.data,
          }
      default:
        return state;
    }
  };
  
  export default dashboardReducer;
  