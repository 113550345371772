import React,{useState, useEffect} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './trades.module.css'
import { useFormik } from 'formik'
import { tradeValidator } from "../../../validationSchema/validator";
import { connect } from 'react-redux';
import { clearTradeAmount, getRateCategory, getRateSubCategory, getTermsAndConditions, getUserRateValue } from '../../../store/actions/rate';
import cogoToast from 'cogo-toast';
import Modal from "../../../components/Modals/FilterModal";
import parse from 'html-react-parser';
import { clearCardTradeSuccess, createCardTrade } from '../../../store/actions/trade';
import { useNavigate } from 'react-router-dom';

const UserStartTradePage = ({fetchCategory, category, fetchSubCategory, 
                    subcategory, amount, calcRate, minAmount, maxAmount,
                     getTerms, terms, accountDetails, handleStartTrade, loader, tradesuccess, clearTradeAmount,clearTradeStatus }) => {

    const navigate = useNavigate()

    useEffect(() =>{
        fetchCategory()
        clearTradeAmount()
     }, [fetchCategory, clearTradeAmount])

    const handleSubCategory = (val) =>{
        fetchSubCategory(val)
        setFieldValue("category", "")
        setFieldValue("amount", "")
        clearTradeAmount()
    }

    const {
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldTouched
    } = useFormik({
        initialValues: {
        giftname: "",
        category: "",
        amount: "",
        comment: ""
        },
        validationSchema: tradeValidator,
        onSubmit(values, { setSubmitting }) {
            handSubmit(values, setSubmitting);
        },
    });

   const handleCalculation = (amount, categoryId, giftname) =>{
    if(giftname === ""){
        setFieldTouched("giftname", true);
    }
    else if(categoryId === ""){
        setFieldTouched("category", true);
    }
    else{
        calcRate(amount, categoryId)
    }   
  }

    const [preview, setPreview] = useState([]);
    const fileobj= [];

    const [showModal, setShowModal] = useState(false)

    const changedHandler = event => {
        let files = event.target.files;
        fileobj.push(files);
        let reader;
    
        for (var i = 0; i < fileobj[0].length; i++) {
            reader = new FileReader();
            reader.readAsDataURL(fileobj[0][i]);
            reader.onload = event => {
            preview.push(event.target.result);   // update the array instead of replacing the entire value of preview
    
            setPreview([...new Set(preview)]); // spread into a new array to trigger rerender
            } 
        } 
    }

    const removeImage = (index) =>{
        const result = [...preview];
      
        // removing the element using splice
        result.splice(index, 1);
      
        // updating the list
        setPreview(result);
      
      }

   const [tradeval, setTradeVal] = useState({});

       // submit func
  const handSubmit = async (values) => {
    console.log(values)
    var res = {
        ...values,
        categoryId: values.giftname,
        subCategoryId: values.category,
        cardAmount: parseFloat(values.amount),
        amount: amount,
        imageUrl: preview
    }
     // check if amount entered is less than minimum expected or greater than maximum expected
     if(parseFloat(values.amount) < minAmount) {
        cogoToast.warn(`The minimum amount for this subcategory is $${minAmount}`)
    } 
    else if(parseFloat(values.amount) > maxAmount){
        cogoToast.warn(`The maximum amount for this subcategory is $${maxAmount}`)
    }
    // check if at least an image of the card is added
    else if(preview.length === 0){
        cogoToast.warn('Kindly attach at least a giftcard!!!')
    }
    else if(preview.length === 11){
      cogoToast.warn('The maximum you can attach is 10 for a trade initialization')
    }
    else{
        // filter by category id and get terms and conditions for a trade
        let id = values.category
        getTerms(id)
        // shows terms and condition modal
        setShowModal(true)
        setTradeVal(res)
    }
  }

   //   confirm a trade
   const confirmTrade = () =>{
    // check if user has a default account filled before starting trade
    if(accountDetails.length === 0){
        cogoToast.info('Please add at least a bank account in the Accounts tab', {position: 'top-right'})
    }
    else{
        // starts and sends data for trade
        handleStartTrade(tradeval)        
    } 
 }

  // after a successful trade, route to trades page 
  useEffect(() =>{
    if(tradesuccess){
        setTimeout(() => {
            navigate('/trades')
            clearTradeStatus()
            clearTradeAmount()
        }, 2000);
    }
}, [tradesuccess, navigate, clearTradeAmount, clearTradeStatus])


    return ( 
        <>
         {/* terms and conditions modal */}
         <Modal
              title="Terms & Conditions"
              show={showModal}
              onClose={() => setShowModal(false)}
            >   
            <div>

            { parse(terms) }  

            <div className="text-center mt-4 ">
                <button
                type="submit"
                onClick={confirmTrade}
                disabled={loader}
                className={`btn ${styles.btn_start}`}
                >
                  Confirm Trade
                </button>
            </div>
           
          </div>

           
          </Modal>

            <UsersSidebar>
                 <p className="titlehead">Start Trade</p>
            </UsersSidebar>
            <div className="usermain">        
              <div className="contain">

                 <div className={`mt-5 ${styles.tradeCard}`}>

                    <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                    <div>
                        <h6 className={`mb-0 ${styles.cardTitle}`}>Upload Giftcard images</h6>
                        <div className={`mt-3 mb-4 ${styles.input_container}`}>
                            <div className={`${styles.input_group} ${styles.cursor_pointer}`}>
                                <div className={styles.custom_file}>
                                <input accept="image/*"        
                                    multiple
                                    onChange={changedHandler}
                                    className={styles.custom_file_input} type="file" />
                                <label className={styles.custom_file_label}>
                                    Add your file(s)
                                </label>
                            </div>
                            </div>         
                        </div>
                         <div className={styles.fileupload}>
                            <div className="row">  
                                {(preview || []).map((url, index) => (
                                    <div className="col-3 mb-3">
                                            <div className={`${styles.imageFile}`}>
                                            <span className={styles.close_icon}
                                                onClick={() => removeImage(index)}
                                            ></span>
                                            <img src={url} alt="..." key={index} 
                                            style={{
                                                maxWidth: '100%',
                                                height: '100px',
                                            borderRadius: '4px'}} />
                                        </div>
                                        </div>
                                    ))}    
                            </div>                                    
                        </div> 
                    </div>
                         
                     </div>

                     <div className={styles.secondHalf}>
                           {/* form submission */}
                          
                            <form onSubmit={handleSubmit}>
                                <div className={styles.form_div}>
                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor='giftname'>Gift Card Name</label>
                                        <select
                                            name="giftname"
                                            values={values.giftname}
                                            onChange={(e) => {
                                                handleChange(e, setFieldValue("category", ""))
                                                handleSubCategory(e.currentTarget.value);
                                                }}
                                            defaultValue=""
                                            onBlur={handleBlur}
                                            className={`${styles.input_style}`}
                                            id="giftname"
                                            >
                                            <option value="" disabled>
                                                    Select
                                            </option>
                                            {category.map((opt, index) => {
                                                return <option key={index} value={opt._id}>{opt.categoryname}</option>
                                                })}
                                            
                                        </select>
                                        <small style={{ color: "#dc3545" }}>
                                            {touched.giftname && errors.giftname}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="category">Gift Card Category</label>
                                        <select
                                            name="category"
                                            values={values.category}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue("amount", "")
                                            }}
                                            defaultValue={values.category}
                                            onBlur={handleBlur}
                                            className={`${styles.input_style}`}
                                            id="category"
                                            >
                                            <option selected={values.category === ""} value="" disabled>
                                                    Select
                                            </option>
                                            {subcategory.map((opt, index) => {
                                                return <option key={index} value={opt._id}>{opt.subcategoryname}</option>
                                            })}
                                            
                                        </select>
                                        <small style={{ color: "#dc3545" }}>
                                            {touched.category && errors.category}
                                        </small>
                                    
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor='amount'>Amount</label>
                                        <div>
                                            <input
                                            type="text"
                                            name="amount"
                                            placeholder="Amount in USD"
                                            className={styles.input_style}
                                            value={values.amount}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleChange(e)
                                                handleCalculation(e.currentTarget.value, values.category, values.giftname);
                                                }}
                                            
                                            />
                                        </div>
                                        <small style={{ color: "#dc3545" }}>
                                            {touched.amount && errors.amount}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor='amount'>You Get</label>
                                        <div>
                                            <input
                                            type="text"
                                            name="amount"
                                            placeholder="Amount in NGN"
                                            className={styles.input_style}
                                            value={`NGN ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled
                                            />
                                        </div>
                                        <small style={{ color: "#dc3545" }}>
                                            {touched.amount && errors.amount}
                                        </small>
                                    </div>


                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor='comment'>Comment</label>
                                        <div>
                                            <textarea
                                            type="text"
                                            name="comment"
                                            placeholder="Enter code(s) if you're trading ecodes or if your card is not clear."
                                            className={styles.input_style}
                                            value={values.comment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            rows="4"
                                            style={{resize: 'none'}}
                                            
                                            />
                                        </div>
                                        <small style={{ color: "#dc3545" }}>
                                            {touched.comment && errors.comment}
                                        </small>
                                    </div>

                                <div className='d-sm-block d-lg-none'>
                                    <h6 className={`mb-0 ${styles.cardTitle}`}>Upload Giftcard images</h6>
                                    <div className={`mt-3 mb-4 ${styles.input_container}`}>
                                        <div className={`${styles.input_group} ${styles.cursor_pointer}`}>
                                            <div className={styles.custom_file}>
                                            <input accept="image/*"        
                                                multiple
                                                onChange={changedHandler}
                                                className={styles.custom_file_input} type="file" />
                                            <label className={styles.custom_file_label}>
                                                Add your file(s)
                                            </label>
                                        </div>
                                        </div>         
                                    </div>
                                    <div className={styles.fileupload}>
                                        <div className="row">  
                                            {(preview || []).map((url, index) => (
                                                <div className="col-6 mb-4">
                                                        <div className={`${styles.imageFile}`}>
                                                        <span className={styles.close_icon}
                                                            onClick={() => removeImage(index)}
                                                        ></span>
                                                        <img src={url} alt="..." key={index} 
                                                        style={{
                                                            maxWidth: '100%',
                                                            height: '100px',
                                                        borderRadius: '4px'}} />
                                                    </div>
                                                    </div>
                                                ))}    
                                        </div>                                    
                                    </div> 
                                </div>
                                    

                                    <div className="mt-4">
                                    <button 
                                        type="submit"
                                        className={`btn ${styles.btn_start}`}
                                    >Start Trade</button>
                                    </div>


                                    
                                </div>
                            </form>
                     </div>

                  </div>


                </div>
             </div>
        </>
     );
}


const mapStateToProps = (state) =>{
    return{
        category: state.rate.category,
        subcategory: state.rate.subcategory,
        amount: state.rate.tradeamount,
        minAmount: state.rate.userminAmount,
        maxAmount: state.rate.usermaxAmount,
        terms: state.rate.terms,
        accountDetails: state.auth.accountDetails,
        loader: state.trade.btnLoader,
        tradesuccess: state.trade.trade_success
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetchCategory: () => dispatch(getRateCategory()),
        fetchSubCategory: (id) => dispatch(getRateSubCategory(id)),
        getTerms: (id) => dispatch(getTermsAndConditions(id)),
        calcRate: (amount, id) => dispatch(getUserRateValue(amount, id)),
        handleStartTrade: (values) => dispatch(createCardTrade(values)),
        clearTradeAmount : () => dispatch(clearTradeAmount()),
        clearTradeStatus: () => dispatch(clearCardTradeSuccess()),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(UserStartTradePage);