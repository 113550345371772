import React,{useEffect, useState} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import { Link, useNavigate } from 'react-router-dom';
import PinInput from "react-pin-input";
import { connect } from 'react-redux';
import { clearResetPinSuccess, SetNewPin } from '../../../store/actions/auth';
import cogoToast from 'cogo-toast';

const UserSetNewPin = ({setNewPin, clearReset, pinresetsuccess}) => {

    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [valid, setValid] = useState(false);

    const navigate = useNavigate()

    const handlePin = (val) => {
        setPin(val);
    };

    const handleConfirmPin = (val) => {
        setConfirmPin(val);
    };

    const handleSubmit = () => {
        // check if pin and confirm pin are correct
        if(pin === ''){
          cogoToast.info('Please enter a new pin for your transactions!')
      }
      else{
        if (pin !== confirmPin) {
            setValid(true);
          } else {
            setValid(false);
            let val = {
              pin,
            };
            setNewPin(val);
          }
      }   
    };

    useEffect(()=>{
      if (pinresetsuccess) {
        setTimeout(() => {
          clearReset();
          navigate("/dashboard");
        }, 3000);
      }
    },[pinresetsuccess,clearReset, navigate])

    return ( 
        <>
            <UsersSidebar>
                 <p className="titlehead">Security</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.Card}`}>
                    <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                         <div>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you feel your pin is compromised? <br/>
                                Tap to change pin
                             </p>

                             <Link
                                to="/security/change-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Reset Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Can't remember your password? <br/>
                                Tap to reset pin
                             </p>
                             <Link
                                to="/security/reset-pin"
                                 className={`mt-3 btn ${styles.cta_btn} ${styles.cta_btn_active}`}
                                >
                                Reset Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Password</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you want to change your password? <br/>
                             Tap to change password
                             </p>
                             <Link
                                to="/security/change-password"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Password
                            </Link>
                         </div>

                     </div>

                     <div className={`${styles.secondHalf}`}>

                         <h6 className={styles.title}>Set New Pin</h6>

                         <p className={`mb-0 mt-3 ${styles.subtitle}`}>Please setup your new transaction pin .</p>

                           {/* pin layout */}
                           <div className='mt-4'>
                                <p
                                className={`${styles.subtitle}`}
                                 >Set Pin</p>
                                <PinInput
                                length={4}
                                initialValue={pin}
                                focus
                                secret
                                type="numeric"
                                onChange={handlePin}
                                inputStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                inputFocusStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                />
                           </div>

                           <div className='mt-4'>
                                <p
                                className={`${styles.subtitle}`}>Confirm Pin</p>
                                <PinInput
                                length={4}
                                initialValue={pin}
                                focus
                                secret
                                type="numeric"
                                onChange={handleConfirmPin}
                                inputStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                inputFocusStyle={{ borderColor: "rgba(244, 125, 32, 0.25)" }}
                                />
                                 <small style={{ color: "rgb(220, 53, 69)" }}>
                                {valid ? "Pin must match!" : ""}
                                </small>
                           </div>

                           <div className="mt-4">
                                <button
                                type="submit"
                                onClick={handleSubmit}
                                 className={`btn ${styles.btn_submit}`}
                              
                                >
                                 Create New Pin
                                </button>
                            </div>


                     </div>

                </div>

                 </div>
             </div>
        </>
     );
}


const mapStateToProps = (state) => {
    return {
      pinresetsuccess: state.auth.pinresetsuccess
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setNewPin: (values) => dispatch(SetNewPin(values)),
      clearReset: (values) => dispatch(clearResetPinSuccess(values)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UserSetNewPin);