import {GetApi} from '../request'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  

// get notifications with limit and offset
export const getNotifications = (limit, offset) => {
  return async (dispatch, getState) => {
      dispatch({ type : 'Loader'})
    try {
      const res = await GetApi(`notifications/user?limit=${limit}&offset=${offset}`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Notifications", data: res.data});
      }
      if(res.status === 400){
        dispatch({ type: "Notify_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};






