import React from 'react';
import styles from './landing.module.css'
import infoBG from '../../assets/images/infoImg.png'
import { Link } from 'react-router-dom';
import heart from '../../assets/icons/heart.svg'

const InfoSection = () => {
    return ( 
        <>
            <div className={styles.info_section}>
                <div className='contain'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 mb-5 mb-lg-0'>

                            <div>
                                <h3 className={styles.info_title}>
                                    We believe in providing our customers with the best possible experience!
                                </h3>
                                <p className={`mt-2 ${styles.info_subtext}`}>
                                Our platform offers the best deal on gift cards and makes it easy to trade. We also have a great customer service team that is always willing to help you with your transactions. 
                                </p>

                                <div>
                                    <Link className={`btn ${styles.info_cta}`} to="/login">Start Trading</Link>
                                </div>
                            </div>

                        </div>

                        <div className='col-lg-6'>

                            <div style={{position: 'relative'}}>
                                <img src={infoBG} alt="info" width='500' height='500' className='img-fluid' />

                                <div className={styles.info_layout_div}>
                                    <div className={styles.info_lay}>
                                        <div className={styles.info_cirlce}>
                                            <img src={heart} alt="heart" width='20' height='20' className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={styles.info_name}>Don’t worry</h6>
                                            <p className={`mt-1 ${styles.info_subtitle}`}>Unbeatable Rates  Dey ground</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
     );
}
 
export default InfoSection;