import React,{useEffect} from 'react'
import verifyImg from '../../assets/images/verified_icon.svg'
import styles from '../Auth/auth.module.css'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import chevron_right from '../../assets/icons/rightchevron.svg'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { verifyUser } from '../../store/actions/auth'


const VerificationStatus = ({verify, msg, status}) => {

  const values = useParams();

  useEffect(()=>{
    document.body.classList.add(styles.verify_body)
  },[])

  useEffect(() => {
    let {code} = values
       const creds = {
         verificationCode: code
       };
      verify(creds);
   }, [verify, values]);


  return (
    <>
      {' '}
      <div className='contain'>
        <div className={styles.verify_container}>
          <div className={styles.verify_icon_container}>
            <img src={verifyImg} alt="Verify Account" />
          </div>
          <div>
            <h3 className={`mt-3 ${styles.verifyacct_p}`}>
              {
                status ? 
                ` Account Verified!` 
                :
                'Account Not Verified'
              }
             </h3>
            <p className={`mt-3 ${styles.linksent_p}`}>
             {
               status ? 
                `Your have successfully verified your Email
                  Please click below to login to you account.`
                  :
                  msg
             } 
            </p>

            <Link to="/login" className={`mt-4 ${styles.verifiedlink_div}`}>
              <p className={styles.verifystatus_link}>Login Here</p>
              <img src={chevron_right} className='img-fluid' alt='arrow' width='6' height='6' />
            </Link>
          </div>

          <div className={styles.footer}>
            <img src={CBlogo_footer} alt="Logo" />
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    msg: state.auth.verified_msg,
    status: state.auth.verified_status,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    verify: (creds) => dispatch(verifyUser(creds)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationStatus)
