import React from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import bankIcon from '../../../assets/icons/bank.svg'
import { Link } from 'react-router-dom';

const UserManagePin = () => {
    return ( 
        <>
        <UsersSidebar>
            <p className="titlehead">Security</p>
        </UsersSidebar>
        <div className="usermain">
             <div className="contain">

               <div className={`mt-5 ${styles.Card}`}>
                     <div className={`${styles.firstHalf}`}>
                         <div>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you feel your pin is compromised? <br/>
                                Tap to change pin
                             </p>

                             <Link
                                to="/security/change-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Reset Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Can't remember your password? <br/>
                                Tap to reset pin
                             </p>
                             <Link
                                to="/security/reset-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Reset Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Password</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you want to change your password? <br/>
                             Tap to change password
                             </p>
                             <Link
                                to="/security/change-password"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Password
                            </Link>
                         </div>

                     </div>

                    <div className={`d-none d-lg-block ${styles.secondHalf}`}>
                        <div className={styles.managePin_Sect}>
                            <img src={bankIcon} width='36' height='36' className='img-fluid' alt="bank" />
                            <h6 className={`mb-0 mt-2 ${styles.title}`}>Secure your account</h6>
                            <p className={`mb-0 mt-2 ${styles.caption_sub}`}>
                            Kindly keep your pin and password safe
                               to avoid unintended access to your account!
                            </p>

                        </div>


                    </div>

               </div>


             </div>
         </div>
        </>
     );
}
 
export default UserManagePin;