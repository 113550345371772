import React, {useState, useEffect, useCallback} from 'react';
import './sidebar.css'  
import {Link, useLocation} from 'react-router-dom'
import Logo from '../../assets/icons/CBLogo.svg'
import home_icon from '../../assets/icons/Dashboard.svg'
import trade_icon from '../../assets/icons/trades_icon.svg'
import wallet_icon from '../../assets/icons/wallets_icon.svg'
import account_icon from '../../assets/icons/accounts_icon.svg'
import setting_icon from '../../assets/icons/Setting.svg'
import profile_icon from '../../assets/icons/profile_icon.svg'
import bell_icon from '../../assets/icons/bell.svg'
import user_p from '../../assets/images/userProfile.svg'
import notify_icon from '../../assets/icons/notify_icon.svg'
import power_icon from '../../assets/icons/power.svg'
import withdraw_icon from '../../assets/icons/withdrawfunds_icon.svg'
import start_icon from '../../assets/icons/start_icon.svg'
import { connect } from 'react-redux';
import { logOut } from '../../store/actions/auth';

const UsersSidebar = (props) => {
  
    const {Logout, isSetPin} = props


    // check for which path you are on
    const isActive = useLocation().pathname;

    const [sideShow, setSideShow] = useState(false);

    const handleDisplay = (name) => {
        let val;
        if (isSetPin) {
          if (isActive.includes(name)) {
            val = "v-list-item active v-list-link";
          } else {
            val = "v-list-item v-list-link";
          }
        }
        else {
          val = "v-list-item disabled";
        }
       
        return val;
      }

    useEffect(() => {
        document.body.classList.add("userBg");
      }, []);
    
    const ToggleSidebar = () => {
        setSideShow(sideShow ? false : true);
    };

    const ToggleLogout = () =>{
        Logout();
     
      }

     const closeSideBar = useCallback(() => {
       setSideShow(sideShow ? false : true);
     }, [sideShow]);
    
    useEffect(() => {
    if (sideShow) {
        document.body.addEventListener("click", closeSideBar);

        return () => document.body.removeEventListener("click", closeSideBar);
    }
    }, [closeSideBar, sideShow]);

    
    return ( 
        <>
          <div className={sideShow ? "userside show" : "userside"}>

            {/* logo */}
             <div className="logo mt-4">
                <img src={Logo} alt="logo" />
             </div>

                {/* nav list */}
                <div className="v-list">

                    <div>
                            <Link
                                to="/dashboard"
                                  className={handleDisplay("/dashboard")}  
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={home_icon} alt="dashboard" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Dashboard
                                        </span>
                                    </div>
                            </Link>

                            
                            <Link
                                to="/start-trade"
                                className={handleDisplay("/start-trade")}  
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={start_icon} alt="start trade" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Start Trade
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/trades"
                                className={handleDisplay("/trade")}  
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={trade_icon} alt="trades" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Trades
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/wallets"
                                className={handleDisplay("/wallet")}  
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={wallet_icon} alt="wallet" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        My Wallet
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/withdraw-funds"
                                className={handleDisplay("/withdraw-funds")}  
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={withdraw_icon} alt="withdraw" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Withdraw Funds
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/accounts"
                                className={handleDisplay("/accounts")}  
                                  
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={account_icon} alt="accounts" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Accounts
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/security/manage-pin"
                                className={handleDisplay("/security")}  
                                    
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                        className="v-list-icon"
                                    >  
                                    <img src={setting_icon} alt="security" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                    Security
                                        </span>
                                    </div>
                            </Link>

                            <Link
                                to="/profile"
                                className={handleDisplay("/profile")}  
                                   
                                    style={{ textDecoration: "none" }}
                                    >
                                    {/* icon */}
                                    <div
                                    className="v-list-icon"
                                    >  
                                    <img src={profile_icon} alt="profile" />   
                                    </div>
                                    {/* title */}
                                    <div className="v-list-title">
                                        <span
                                        >
                                        Profile
                                        </span>
                                    </div>
                            </Link>
                    </div>

                    {/* sidebar footer */}
                    <div className='sidebar-footer-div'>
                        <Link to="/notifications"
                         style={{ textDecoration: "none" }}
                            className={handleDisplay("/notifications")}  
                                >
                            <div className='sidebar-footer-img'>
                                <img src={notify_icon} alt="notifictions" />  
                            </div>
                            <div className='sidebar-footer-text'>
                                <span>Notifications</span>
                            </div>
                        </Link>

                        <Link to="/login" onClick={ToggleLogout} className='sidebar-footer-layout'>
                            <div className='sidebar-footer-img'>
                                <img src={power_icon} alt="logout icon" />  
                            </div>
                            <div className='sidebar-footer-text'>
                                <span>Logout</span>
                            </div>
                        </Link>
                        
                    </div>
                        
                </div>
          </div>

            {/* navbar */}
            <nav>
                <div className="sidehead">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {/* icon for hamburger */}
                        <div
                        onClick={ToggleSidebar}
                        className="d-md-none d-sm-block"
                        style={{ marginRight: "12px", cursor: "pointer" }}
                        >
                        <i className="mdi mdi-menu" style={{ fontSize: 24, color:"#F47D20" }}></i>
                        </div>

                        {/* back arrow */}
                        <div>
                        {props.children}  
                        </div>  
                    
                        <div style={{ flexGrow: 1 }}></div>
                    
                        <div className='nav-right'>
                            <div className='bell_div mr-3 mr-lg-3'>
                                <img
                                className="imageStyle"
                                src={bell_icon}
                                alt="user"
                                />
                            </div>

                            <div>
                                <img src={user_p} alt='user' className='img-fluid user_p' />
                            </div>
                        </div>
                        
                    </div>
                </div>
           </nav> 
           
        </>
     );
}
 

const mapStateToProps = (state) =>{
    return{
        isSetPin: state.auth.isSetPin,
        isAuthenticated: state.auth.isAuthenticated,
    }
}

  const mapDispatchToProps = (dispatch) =>{
    return{
        Logout: () => dispatch(logOut()),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(UsersSidebar);