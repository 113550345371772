import React from 'react';
import styles from './landing.module.css'
import heroimg from '../../assets/images/heroImg1.png'
import line2 from '../../assets/icons/line2.svg'
import heart from '../../assets/icons/heart.svg'
import box from '../../assets/icons/box.svg'
import {Link} from 'react-router-dom'

const HeroSection = () => {
    return ( 
        <>
            <div className={styles.section_hero}>
                <div className='contain'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 mb-5 mb-lg-0'>
                            <div>
                                <h1 className={styles.hero_title}>
                                The Best way to trade  gift cards for naira! 
                                
                                </h1>
                                <p className={`mt-3 ${styles.hero_subtitle}`}>
                                Easy and convenient way to sell your gift cards for naira.
                                </p>

                                <div className={`mt-4 ${styles.hero_cta}`}>
                                    <div>
                                        <Link to="/signup" className={`btn ${styles.btnRegister}`}>Register</Link>
                                    </div>
                                    <div>
                                    <Link to="/login" className={`btn ${styles.btnSignIn}`}>Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className={styles.heroImgSect}>
                                <img src={heroimg} className={`img-fluid ${styles.heroImg}`} alt="hero" />

                                <img src={line2} alt="lines" className={`img-fluid ${styles.line2}`} />
                                <img src={box} alt="box" className={`img-fluid ${styles.box}`} />

                                <div className={styles.box_layout_div}>
                                    <div className={styles.box_lay}>
                                        <div className={styles.box_cirlce}>
                                            <img src={heart} alt="heart" width='20' height='20' className='img-fluid' />
                                        </div>
                                        <div>
                                            <h6 className={styles.box_name}>Miss. Grace Adejare</h6>
                                            <p className={`mt-1 ${styles.box_subtext}`}>Fastest Payout I ever received</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
     );
}
 
export default HeroSection;