import React,{useEffect} from 'react';
import CardsSection from '../../components/LandingComponents/Cards';
import FeaturesSection from '../../components/LandingComponents/Features';
import Footer from '../../components/LandingComponents/Footer';
import HeroSection from '../../components/LandingComponents/Hero';
import InfoSection from '../../components/LandingComponents/Info';
import Navbar from '../../components/LandingComponents/Navbar';
import StepsSection from '../../components/LandingComponents/Steps';
import TestimonialsSection from '../../components/LandingComponents/Testimonials';


const LandingPageLayout = () => {

  useEffect(() => {
      document.body.classList.add("homeBg");
   }, []);

   useEffect(() => {
    const script = document.createElement("script");
    script.src = "//code.tidio.co/ggfzb1cnmlqry3ztvmdxyxivlv0rhv3a.js";
    script.async = true;
    document.body.appendChild(script);
 }, []);
   
  return ( 
        <>
          <Navbar />
          <HeroSection />
          <CardsSection />
          <StepsSection />
          <InfoSection />
          <FeaturesSection />
          <TestimonialsSection />
          <Footer />
        </>
   );
}
 
export default LandingPageLayout;