const initState = {
    allCount: 0,
    notifications: []
  };
  
  const notifyReducer = (state = initState, action) => {
    switch (action.type) {
      case 'Notifications':
          return {
                ...state,
                allCount: action.data.allCount,
                notifications: action.data.findNotification
            }
      default:
        return state;
    }
  };
  
  export default notifyReducer;
  