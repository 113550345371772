import React, { useEffect } from 'react'
import Navbar from '../../components/LandingComponents/Navbar';
import styles from './rates.module.css'
import arcImg from '../../assets/images/shapearc.png'
import Footer from '../../components/LandingComponents/Footer';
import { Form, Formik } from 'formik'
import { rateValidator } from '../../validationSchema/validator'
import Button from '../../components/Button/Button'
import proceed_icon from '../../assets/icons/proceed_icon.svg'
import { Link } from 'react-router-dom';
import {connect} from 'react-redux'
import { getRateCategory, getRateSubCategory, getRateValue } from '../../store/actions/rate';


const RateCalculatorPage = ({fetchCategory, category, fetchSubCategory, subcategory, calcRate, amount}) => {

    useEffect(() =>{
        fetchCategory()
  }, [fetchCategory])

  const handleSubCategory = (val) =>{
    fetchSubCategory(val)
   }

    useEffect(()=>{
        document.body.classList.add(styles.ratesBG)
    },[])

    // calculate func
  const handleSubmit =  (values, setSubmitting) => {
    setSubmitting(true)
    console.log(values)
    var amount = values.amount;
    var categoryId =  values.category

    calcRate(amount, categoryId)

    setTimeout(() => {
        setSubmitting(false);
    }, 1000);
  }

    return (
        <>
            <Navbar />
            <div className={styles.rates_section}>
                <div className='contain'>

                   <div className={styles.titleDiv}>
                        <img src={arcImg} alt="arcImg" className='img-fluid' />
                        <h3 className={styles.title}>GiftCard Rate Calculator</h3>
                    </div>

                 <div className={`mt-5 ${styles.calculator_div}`}>
                       <Formik
                        onSubmit={(values, { setSubmitting }) =>
                            handleSubmit(values, setSubmitting)
                        }
                        validationSchema={rateValidator}
                        initialValues={{ giftname: '', category: '', amount: '' }}
                        >
                        {({
                            handleChange,
                            isSubmitting,
                            handleSubmit,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            errors,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className={styles.form_div}>
                                <div className={`mb-3 ${styles.input_container}`}>
                                    <label htmlFor='giftname'>Gift Card Name</label>
                                    <select
                                        defaultValue=""
                                        name="giftname"
                                        values={values.giftname}
                                        onChange={(e) => {
                                        handleChange(e, setFieldValue("category", ""))
                                        handleSubCategory(e.currentTarget.value);
                                        }}
                                        onBlur={handleBlur}
                                        className={`${styles.input_style}`}
                                        id="giftname">
                                        <option value='' disabled>Select</option>
                                        {category.map((opt, index) => {
                                                    return <option key={index} value={opt._id}>{opt.categoryname}</option>
                                            })} 
                                    </select>
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.giftname && errors.giftname}
                                    </small>
                                </div>

                                <div className={`mb-3 ${styles.input_container}`}>
                                <label htmlFor='category'>Gift Card Category</label>     
                                     <select
                                        defaultValue=""
                                        name="category"
                                        values={values.category}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={`${styles.input_style}`}
                                        id="category">
                                        <option selected={values.category === ""} value="">Select</option>
                                        {subcategory.map((opt, index) => {
                                         return <option key={index} value={opt._id}>{opt.subcategoryname}</option>
                                        })} 
                                    </select>
                                    <small style={{ color: "#dc3545" }}>
                                        {touched.category && errors.category}
                                    </small>
                                </div>

                                <div className={`${styles.input_container}`}>
                                <label htmlFor='amount'>Gift Card Amount</label>
                                <div style={{ position: 'relative' }}>
                                    <div className={styles.amount_unit}>
                                        USD
                                    </div>
                                    <input
                                    type="text"
                                    placeholder="0.00"
                                    className={styles.input_style}
                                    value={values.amount}
                                    id="amount"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                </div>
                                <small style={{ color: '#dc3545' }}>
                                    {touched.amount && errors.amount}
                                </small>
                                </div>

                                <div className="mt-5">
                                    <Button disabled={isSubmitting} title="Calculate" />
                                </div>
                            </div>
                            </Form>
                        )}
                        </Formik>

                        {/* resuls section */}
                        <div className={`mt-5 ${styles.result_div}`}>
                            <div className='text-center'>
                              <p className={styles.result_text}>You Get</p>
                              <h3 className={`mt-1 ${styles.result_amount}`}>
                              ₦{amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,",")} 
                              </h3>

                              <div className={styles.proceed_div}>
                                  <img src={proceed_icon} className='img-fluid' alt="proceed" />
                                  <Link to="/login" className={styles.proceed_text}>Proceed to trade now</Link>
                              </div>
                            </div>
                         

                        </div>


                    </div>


                </div>
            </div>
            <Footer />
        </>
      );
}

const mapStateToProps = (state) =>{
    return{
        category: state.rate.category,
        subcategory: state.rate.subcategory,
        amount: state.rate.amount
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        fetchCategory: () => dispatch(getRateCategory()),
        fetchSubCategory: (id) => dispatch(getRateSubCategory(id)),
        calcRate: (amount, id) => dispatch(getRateValue(amount, id))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RateCalculatorPage);