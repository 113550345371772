import {GetApi} from '../request'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  

// get dashboard analytics
export const getDashboardAnalytics = (status, from_date, to_date) => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi(`auth/dashboard/user`, getToken(),);
      if (res.status === 200) {
        dispatch({ type: "Analytics", data: res.data.message});
      }
      if(res.status === 400){
        dispatch({ type: "Analytics_Error", err: res.data });
      }
    } catch (err) {
     console.log(err)
    }
  };
};




