import React,{useEffect, useRef} from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { ResetTokenValidator } from '../../../validationSchema/validator'
import { connect } from 'react-redux';
import { clearValidToken, verifyResetPinCode } from '../../../store/actions/auth';

const UserResetPinToken = ({verifyCode, clearToken, email, validtoken}) => {

    const navigate = useNavigate()

    const ref = useRef()

// submit func
  const handleSubmit = async (values) => {
     await verifyCode(values)
  }

  const maskEmail = (email = "") => {
    const [name, domain] = email.split("@");
    const { length: len } = name;
    const maskedName = name.substring(0,5) + "****" + name[len - 1];
    const maskedEmail = maskedName + "@" + domain;
    return maskedEmail;
 };

 useEffect(() => {
    if (validtoken) {
      // reset the form
      setTimeout(() => {
        clearToken();
        navigate("/security/set-pin");
      }, 3000);
    }
 }, [validtoken, clearToken, navigate]);


    return (  
        <>
            <UsersSidebar>
             <p className="titlehead">Security</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.Card}`}>
                    <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                         <div>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you feel your pin is compromised? <br/>
                                Tap to change pin
                             </p>

                             <Link
                                to="/security/change-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Reset Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Can't remember your password? <br/>
                                Tap to reset pin
                             </p>
                             <Link
                                to="/security/reset-pin"
                                 className={`mt-3 btn ${styles.cta_btn} ${styles.cta_btn_active}`}
                                >
                                Reset Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Password</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you want to change your password? <br/>
                             Tap to change password
                             </p>
                             <Link
                                to="/security/change-password"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Password
                            </Link>
                         </div>

                     </div>

                     <div className={`${styles.secondHalf}`}>

                     <h6 className={styles.title}>Reset Pin</h6>

                     <p className={`mb-0 mt-2 ${styles.subtitle}`}>Please provide the token sent to {maskEmail(email)}</p>

                        <div className={`mt-4`}>
                            {/* form submission */}
                            <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                                }
                                validationSchema={ResetTokenValidator}
                                initialValues={{
                                    code: "",
                                }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                }) => (
                                <Form ref={ref} onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                    <div
                                        className={`mt-3 mb-3 ${styles.input_container}`}
                                    >
                                        <label htmlFor="code">
                                          Token
                                        </label>
                                        <div>
                                        <input
                                            name="code"
                                            type='text'
                                            placeholder="Enter token"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.code}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.code &&
                                            errors.code}
                                        </small>
                                    </div>
                                 

                                    <div className="mt-4">
                                        <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_submit}`}
                                        >
                                       Next
                                        </button>
                                    </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        </div>

                     </div>

                </div>




                 </div>
             </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      email: state.auth.profile.email,
      validtoken: state.auth.validtoken,
      isAuthenticated: state.auth.isAuthenticated,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      verifyCode: (values) => dispatch(verifyResetPinCode(values)),
      clearToken: () => dispatch(clearValidToken()),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(UserResetPinToken);