import React from 'react';
import UsersSidebar from '../../../components/UserSidebar/sidebar';
import styles from './security.module.css'
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik'
import { ChangePasswordValidator } from '../../../validationSchema/validator'
import { connect } from 'react-redux';
import { ChangePassword } from '../../../store/actions/auth';

const UserChangePassword = ({savePassword}) => {

      // submit func
      const handleSubmit = async (values) => {
        await savePassword(values)
      }

      
    return (
        <>
            <UsersSidebar>
              <p className="titlehead">Security</p>
            </UsersSidebar>
            <div className="usermain">
                 <div className="contain">

                 <div className={`mt-5 ${styles.Card}`}>
                    <div className={`d-none d-lg-block ${styles.firstHalf}`}>
                         <div>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you feel your pin is compromised? <br/>
                                Tap to change pin
                             </p>

                             <Link
                                to="/security/change-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Change Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Reset Pin</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Can't remember your password? <br/>
                                Tap to reset pin
                             </p>
                             <Link
                                to="/security/reset-pin"
                                 className={`mt-3 btn ${styles.cta_btn}`}
                                >
                                Reset Pin
                            </Link>
                         </div>

                         <div className='mt-5'>
                             <h6 className={`mb-0 mt-2 ${styles.title}`}>Change Password</h6>
                             <p className={`mb-0 mt-2 ${styles.subtitle}`}>
                             Do you want to change your password? <br/>
                             Tap to change password
                             </p>
                             <Link
                                to="/security/change-password"
                                 className={`mt-3 btn ${styles.cta_btn} ${styles.cta_btn_active}`}
                                >
                                Change Password
                            </Link>
                         </div>

                     </div>

                     <div className={`${styles.secondHalf}`}>

                     <h6 className={styles.title}>Change Password</h6>

                        <div className={`mt-4`}>
                            {/* form submission */}
                            <Formik
                                onSubmit={(values, { setSubmitting }) =>
                                handleSubmit(values, setSubmitting)
                                }
                                validationSchema={ChangePasswordValidator}
                                initialValues={{
                                  password: "",
                                  newpassword: "",
                                  confirm_password: "",
                                }}
                            >
                                {({
                                handleChange,
                                isSubmitting,
                                handleSubmit,
                                handleBlur,
                                values,
                                touched,
                                errors,
                                }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={styles.form_div}>
                                    <div
                                        className={`mt-3 mb-3 ${styles.input_container}`}
                                    >
                                        <label htmlFor="password">
                                        Current Password
                                        </label>
                                        <div>
                                        <input
                                            name="password"
                                            type='password'
                                            placeholder="Enter your current password"
                                            className={styles.input_style}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.password &&
                                            errors.password}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="newpassword">New Password</label>
                                        <div>
                                        <input
                                            type="password"
                                            name="newpassword"
                                            placeholder="Enter a new password"
                                            className={styles.input_style}
                                            value={values.newpassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.newpassword && errors.newpassword}
                                        </small>
                                    </div>

                                    <div className={`mb-3 ${styles.input_container}`}>
                                        <label htmlFor="confirm_password">Confirm Password</label>
                                        <div>
                                        <input
                                            type="password"
                                            name="confirm_password"
                                            placeholder="Confirm your password"
                                            className={styles.input_style}
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        </div>
                                        <small style={{ color: '#dc3545' }}>
                                        {touched.confirm_password && errors.confirm_password}
                                        </small>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={`btn ${styles.btn_submit}`}
                                        >
                                        Change Password
                                        </button>
                                    </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                        </div>

                     </div>

                </div>

                 </div>
             </div>
        </>
      );
}



const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated,
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      savePassword: (values) => dispatch(ChangePassword(values)),
    };
  }
   
 
export default connect(mapStateToProps, mapDispatchToProps)(UserChangePassword);