import cogoToast from "cogo-toast";
import { PostApi, PutApi, DeleteApi, GetApi } from "../request";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

// login user actions functionality
export const loginUser = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(`auth/exchanger/login`, { ...user }, "", "application/json");
      if (res.status === 200) {
        var role = res.data.message.profile.role;
         switch (role) {
           case "Exchanger":
             dispatch({ type: "LOGIN_SUCCESS", data: res.data.message });
             cogoToast.success("Login Successful!", {
               position: "bottom-right",
             });
            break;
           default:
             cogoToast.error("User not authorized to access this page!");
             break;
         }       
      }
       if (res.status === 400) {
         cogoToast.error(`${res.data.message}`);
       }
       if(res.status === 403){
        cogoToast.error("User not authorized to access this page!");
       }
    } catch (err) {
      console.log(err);
    }
  };
};

// forgot password functionality
export const forgotPassword = (user) => {
  return async (dispatch, getState) => {
     try {
       const res = await PostApi(`auth/forgot/password`, { ...user }, "", "application/json");
       if (res.status === 200) {
          cogoToast.success(
            "Check your email for password reset instructions!",
            {
              position: "top-center",
            }
          );
       }
        if (res.status === 400) {
          cogoToast.error(
            "Kindly check that the credentials entered is valid!"
          );
        }
     } catch (err) {
       console.log(err);
     }
  }
}


// verify forgot password code sent to email
export const verifyResetCode = (code) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi("auth/link/verify/forgotpasswordcode", {code}, "", "application/json")
      if (res.status === 200) {
        dispatch({ type: 'VALID_RESETCODE' });
      }
      if(res.status === 400){
        // Invalid code
        dispatch({ type: 'INVALID_RESETCODE' });
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// reset password functionality
export const ResetUsersPassword = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "auth/reset/password",
        { ...val },
        "",
        "application/json"
      );
      if (res.status === 200) {
        // reset a user's password
        dispatch({ type: 'PasswordSuccess' });
        cogoToast.success("Password successfully changed, Login to continue", {
          position: "top-center",
        });
      }
      if(res.status === 400){
        // error while reset password
        var msg = res.data.message;
        dispatch({ type: 'RESET_ERROR' });
        cogoToast.error(`${msg}`);
      }
    } catch (err) {
      console.log(err)
    }
  };
};


// sign up user functionality
export const signUp = (user) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        "auth/exchanger/new",
        {
          firstName: user.firstname,
          lastName: user.lastname,
          phoneNumber: user.phone,
          email: user.email,
          password: user.password,
          role: "Exchanger"
        },
        "",
        "application/json"
      );
      if (res.status === 201) {
        dispatch({ type: "SIGNUP_SUCCESS", data: user.email });
        cogoToast.success("Registration Successful!");
      }
      if(res.status === 400){
        dispatch({ type: "SIGNUP_FAIL", err: res.data});
        cogoToast.error('Oops! Looks like a user already exist with this email.')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

 // clear sign up success status
 export const clearSignUp = () =>{
  return dispatch =>{
      dispatch({type: 'clearSignUpSuccess'})
  }
}


// resend verification link
export const resendLink = (user) => {
  return async (dispatch, getState) => {
    dispatch({ type: "LINK_STATUS_ENABLED" });
    try {
      const res = await PostApi(
        `auth/resendverificationlink`,
        { ...user },
        "",
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "LINK_STATUS_DISABLED" });
        cogoToast.success("Verification link sent successfully!", { position: "top-right" });
      }
    } catch (err) {
      console.log(err);
       dispatch({ type: "LINK_STATUS_DISABLED" });
    }
  };
};

// verify new user - with the verification code sent to email
export const verifyUser = (code) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi(
        `auth/verify/user`,
        { ...code },
        "",
        "application/json"
      );
      if (res.status === 200) {
         dispatch({ type: "VerifiedUser", data: res.data.message });
        cogoToast.success("Email verified successfully", { position: "bottom-right" });
      }
      if (res.status === 400) {
        dispatch({ type: "VerifiedUserError", data: res.data.message });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

//   Set pin functionality
export const SetPin = (val) => {
  return async (dispatch, getState) => {
    dispatch({ type: "startpinLoad" });
    const values = {
      pin: parseInt(val.pin),
    };
    try {
      const res = await PostApi(
        "auth/createpin",
        { ...values },
        getToken(),
        "application/json"
      );
      if (res.status === 201) {
        cogoToast.success('Pin created successfully!')
        dispatch({ type: "stoppinLoad" });
        dispatch({ type: "PinCreateSuccess" });
      }
      if(res.status === 400){
        cogoToast.error('Error while creating pin')
         dispatch({ type: "stoppinLoad" });
      }
    } catch (err) {
      console.log(err)
    }
  };
};


// create account details functionality
export const addAccountDetails = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi("auth/accountdetails/new", {
        ...val
      }, getToken(), "application/json")
      if (res.status === 200) {
        // add a new account
        dispatch({
          type: "AccountCreateSuccessful",
          data: res.data.accountDetails,
        });
        cogoToast.success("Account details added successfully!");
      }
      if(res.status === 400){
        // error while adding account
        dispatch({ type: 'Account_Error' });
        cogoToast.error("Error while adding account details")
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// update account details
export const updateAccountDetails = (val) => {
  return async (dispatch, getState) => {
    try {
       let id = getState().auth.details.id;
      const res = await PutApi(
        "auth/accountdetails/"+id,
        {
          ...val,
        },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        // update account details
        dispatch({
          type: "AccountUpdatedSuccessful",
          data: res.data.accountDetails,
        });
        cogoToast.success("Account details updated successfully!");
      }
      if (res.status === 400) {
        // error while adding account
        dispatch({ type: "Account_Error" });
        cogoToast.error("Error while updating account details");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// delete account details
export const deleteAccountDetails = (id) => {
  return async (dispatch, getState) => {
    try {
      const res = await DeleteApi(
        "auth/accountdetails/" + id,
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        // update account details
        dispatch({
          type: "AccountDeletedSuccessful",
          data: res.data.accountDetails,
        });
        cogoToast.success("Account details deleted successfully!");
      }
      if (res.status === 400) {
        // error while adding account
        dispatch({ type: "Account_Error" });
        cogoToast.error("Error while deleting account details");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// get list of all banks (nigeria accounts)
export const getBankAccounts = () => {
  return async (dispatch, getState) => {
    try {
      const res = await GetApi(`notifications/bank/code`, getToken());
      if (res.status === 200) {
        dispatch({ type: "Banks", data: res.data.bankCode.data });
      }
      if (res.status === 400) {
        dispatch({ type: "Banks_Error", err: res.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};


//   Change password functionality
export const ChangePassword = (user) => {
  return async (dispatch, getState) => {
    
    const values = {
      oldPassword: user.password,
      newPassword: user.newpassword
    }
    try {
      const res = await PostApi("auth/change/password", { ...values }, getToken(), "application/json");
      if (res.status === 200) {
         localStorage.setItem("token", "");
         dispatch({ type: "logout", err: "User Out" });
        cogoToast.success('Password updated successfully! Kindly Login again.', { position: 'bottom-right', })
      }
      if(res.status === 400){
        cogoToast.error('Check that your old password is correct!')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

//   Change pin functionality
export const ChangePin = (user) => {
  return async (dispatch, getState) => {
    
    const values = {
      oldPin: parseInt(user.pin),
      newPin: parseInt(user.newpin),
    };
    try {
      const res = await PutApi(
        "auth/changepin",
        { ...values },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        cogoToast.success('Pin updated successfully!')
        dispatch({ type: "PinSuccess" });
      }
      if(res.status === 400){
        cogoToast.error('Check that your old pin is correct!')
          dispatch({ type: "PinFail" });
      }
    } catch (err) {
      console.log(err)
    }
  };
};

// reset pin success
export const clearPinSuccess = () => {
  return (dispatch, getState) => {
    dispatch({ type: "ClearPinSuccess" });
  };
};

// forget pin functionality
export const ForgetPin = (user) => {
  return async (dispatch, getState) => {

    const values = {
      email: user.email,
      phoneNumber: user.phoneNumber,
    };
    try {
      const res = await PostApi(
        "auth/forgotpin",
        { ...values },
        getToken(),
        "application/json"
      );
      if (res.status === 201) {
        cogoToast.success("Request completed, please proceed!");
        dispatch({ type: "ForgetPinSuccess" });
      }
      if (res.status === 400) {
        cogoToast.error("Check that the details entered are correct!");
          dispatch({ type: "ForgetPinFail" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// clear forget pin success
export const clearForgetPinSuccess = () => {
 return (dispatch, getState) => {
   dispatch({ type: "clearForgetPinSuccess" });
 };
};

// verify reset pin token 
export const verifyResetPinCode = (val) => {
  return async (dispatch, getState) => {
    const code = val.code
    try {
      const res = await PostApi(
        "auth/link/verify/pincode",
        { ...val },
        "",
        "application/json"
      );
      if (res.status === 200) {
        dispatch({ type: "VALID_TOKEN", data: code });
        cogoToast.success("Token valid, please proceed to set your new pin!");
      }
      if (res.status === 400) {
        // Invalid code
        dispatch({ type: "INVALID_TOKEN" });
        cogoToast.error("This token is either invalid or has expired!");
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// clear reset pin token
export const clearValidToken = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearValidToken" });
  };
};

// set new pin
export const SetNewPin = (user) => {
  return async (dispatch, getState) => {
    const values = {
      code: getState().auth.token_value,
      pin: parseInt(user.pin),
    };
    try {
      const res = await PostApi(
        "auth/resetpin",
        { ...values },
        getToken(),
        "application/json"
      );
      if (res.status === 200) {
        cogoToast.success("Pin reset successful!");
        dispatch({ type: "PinResetSuccess" });
      }
      if (res.status === 400) {
        cogoToast.error("Error while resetting pin!");
        dispatch({ type: "PinResetFail" });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// clear reset pin success
export const clearResetPinSuccess = () => {
  return (dispatch, getState) => {
    dispatch({ type: "clearResetPinSuccess" });
  };
 };

 // api call for contact functionality on landing page
export const contactSupport = (val) => {
  return async (dispatch, getState) => {
    try {
      const res = await PostApi("notifications/support", 
      {
        firstName: val.firstName,
        lastName: val.lastName,
        email: val.email,
        message: val.message,
        subject: "New Support Request"
      }, "", "application/json")
      if (res.status === 200) {
         dispatch({ type: "CONTACT_SUCCESS" });
         cogoToast.success('Thanks for getting across to us!', { position: 'top-right', })
      }
      if(res.status === 400){
        dispatch({ type: "CONTACT_ERROR", err: res.data});
        cogoToast.error('Error sending message!')
      }
    } catch (err) {
      console.log(err)
    }
  };
};

 

// logout a user
export const logOut = () => {
  return (dispatch, getState) => {
    localStorage.setItem("token", "");
    dispatch({ type: "logout", err: "User Out" });
  };
};