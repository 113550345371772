import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";

const UserRoute = ({ children, isAuthenticated}) => {
  const location = useLocation();
  return (
    <>
        {
            !isAuthenticated ? 
            <Navigate to ="/login"
            replace
            state={{path: location.pathname}}
             />
            :
            children
        }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(UserRoute);