import React from 'react';
import styles from './landing.module.css'
import client1 from '../../assets/images/client1.svg'
import client2 from '../../assets/images/client2.svg'
import client3 from '../../assets/images/client3.svg'
import client4 from '../../assets/images/client4.svg'
import client5 from '../../assets/images/client5.svg'
import client6 from '../../assets/images/client6.svg'

const TestimonialsSection = () => {
    return (  
        <>
            <div className={styles.testimonials_section}>
                <div className='contain'>
                    <div className='text-center'>
                        <h2 className={styles.test_title}>Trusted by Thousands of TRADERS!</h2>
                    </div>

                    <div className={`${styles.testimonial_card}`}>
                        <p className={styles.testimonial_text}>
                        “The customer care that reached out to me about the role were super helpful, understanding and amazing. They were literally with me at every step of the trading process and finally to getting the Payed. Thank you CardBarter.”
                        </p>

                        <div className={styles.client1}>
                            <img src={client1} alt="user" width='70' height='70' classNAme="img-fluid" />
                        </div>

                        <div className={styles.client2}>
                            <img src={client2} alt="user" width='80' height='80' classNAme="img-fluid" />
                        </div>

                        <div className={styles.client3}>
                            <img src={client3} alt="user" width='100' height='100' classNAme="img-fluid" />
                        </div>

                        <div className={styles.client4}>
                            <img src={client4} alt="user" width='70' height='70' classNAme="img-fluid" />
                        </div>

                        <div className={styles.client5}>
                            <img src={client5} alt="user" width='70' height='70' classNAme="img-fluid" />
                        </div>

                        <div className={styles.client6}>
                            <img src={client6} alt="user" width='60' height='60' classNAme="img-fluid" />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
 
export default TestimonialsSection;