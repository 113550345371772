import React from 'react';
import styles from './policy.module.css'
import Navbar from '../../components/LandingComponents/Navbar';
import arcImg from '../../assets/images/shapearc.png'
import Footer from '../../components/LandingComponents/Footer';

const AMLPolicyPage = () => {
    return (  
        <>
         <Navbar />
            <div className={styles.policy_section}>
                <div className='contain'>

                    <div className={styles.titleDiv}>
                        <img src={arcImg} alt="arcImg" className='img-fluid' />
                        <h3 className={styles.titleP}>AML Policy</h3>
                    </div>

                    <div className={`mt-5 mb-5 ${styles.terms_content}`}>

                        <div className=''>
                            <h6>OVERVIEW </h6>
                            <p className='mt-3'>
                            Money laundering is a serious international financial crime that entails the illegal concealment or disguise of the nature, location, source, ownership, or control of money.
                            </p>
                            <p>
                               On the other hand, anti-money laundering (AML) is a global phrase for preventing money laundering. It consists of policies, rules, and regulations aimed at limiting or totally eliminating financial crimes. To combat these nefarious crimes, global and local authorities have been established around the world, with each country creating its own AML regulation. These regulations must be followed by companies and organizations all across the world.
                            </p>
                            <p>
                                 Companies and organizations are required by local and international regulations to implement effective internal policies to prevent money laundering, terrorism financing, narcotics and human trafficking, weapons proliferation, corruption, and bribery; and to report any suspicious activity from their customers immediately. CardBarter has put in place a policy that is specifically designed to minimize and mitigate the danger of becoming involved in unlawful conduct.
                            </p>
                            <p>
                            Money laundering can be done in a variety of ways, and as technology progresses, these methods grow more sophisticated. Money laundering is a tough subject to explain because it varies greatly from one transaction to the next as a result of these constant improvements. The amount, nature of the transaction, and frequency of deposits/withdrawals from the system by users will all be used to determine whether transactions are suspicious.
                            </p>       
                        </div>
                        
                        <div className='mt-5'>
                            <h6>CardBarter AML Policy procedure includes:</h6>
                            <ol className='mt-3'>
                                <li className='mb-2 ml-3'>
                                Customers Identity Verification
                                </li>
                                <li className='mb-2 ml-3'>
                                    Compliancy
                                </li>
                                <li className='mb-2 ml-3'>
                                 Risk Assessment
                                </li>
                                <li className='mb-2 ml-3'>
                                Monitoring Transactions
                                </li>
                                <li className='mb-2 ml-3'>
                                Internal Control 
                                </li>
                            </ol>
                            <p>
                            These procedures were created to ensure compliance with all applicable BSA and FINRA requirements, and they will be reviewed and updated on a regular basis to ensure that suitable policies, procedures, and internal controls are in place to keep up with any current developments.
                            </p>
                        </div>

                        <div className='mt-5'>
                            <h6>CUSTOMERS IDENTITY VERIFICATION </h6>
                            <p className='mt-3'>
                            Users’ verification procedures will be carried out in accordance with anti-money laundering and “Know Your Customer” framework standards. Customer due diligence (“CDD”) is one of the worldwide criteria for preventing illicit conduct. Customers must submit additional documents, data, or information (e.g., National ID, International passport, Voter’s Card, Bank statement, Utility bill) that are reliable, up-to-date, and independent.
                            </p>
                            <p>
                            The extra information you submit will be verified. CardBarter retains the right to investigate clients who have been flagged as suspicious and will use all legal ways to double-check identifying information.
                            </p>
                            <p>
                            Identification information will be collected, stored, safeguarded, and shared in accordance with CardBarter’s Policy once the customer’s identity has been validated. CardBarter has the ability to absolve itself of any potential legal culpability.
                            </p>

                        </div>

                        <div className='mt-5'>
                            <h6>COMPLIANCY</h6>
                            <p className='mt-3'>
                            CardBarter has given this office the authority to oversee all parts of its anti-money laundering policy and to ensure that the policy’s processes are being followed correctly. This can include, but is not limited to:
                            </p>
                            <ol className='mt-3'>
                                <li className='mb-2 ml-3'>
                                Regularly updating risk assessments by providing transaction details to appropriate authorities as required by applicable laws and regulations.
                                </li>
                                <li className='mb-2 ml-3'>
                                Overseeing transactions and looking into any suspicious activity that arises. This is normally accomplished by storing and retrieving documents, files, forms, and logs using a records management system.
                                </li>
                                <li className='mb-2 ml-3'>
                                Gathering customer identification information, as well as establishing and updating internal policies and procedures for the completion, evaluation, submission, and storage of all reports and records required by applicable laws and regulations.
                                </li>   
                            </ol>
                           
                        </div>

                        <div className='mt-5'>
                            <h6>RISK ASSESSMENT</h6>
                            <p className='mt-3'>
                            To prevent money laundering and terrorist financing, this office has been outfitted with risk-based procedures. CardBarter can use these methods to verify that the safeguards in place to prevent or decrease money laundering and other similar activities correspond to the risks highlighted. As a result, resources will be used in the most effective manner possible. Although independent, this office will report all risk and compliance issues to the board of directors on a regular basis, in accordance with the requirements of the Money Laundering (Prohibition) Act, 2011 (as amended), Terrorism (Prevention) Act, 2011 (as amended), and Terrorism Prevention (Freezing of International Terrorists Funds and Other Related Measures) Regulations 2013, as well as other laws and regulations.
                            </p>
                        </div>

                        <div className='mt-5'>
                            <h6>MONITORING TRANSACTIONS </h6>
                            <p className='mt-3'>
                            Customers' identities are validated not only by their transactional patterns, but also through their identification. As a result, as a risk assessment and suspicion detection tool, CardBarter relies on data analysis. This office is responsible for a variety of compliance-related responsibilities, including as data collection, filtering, storage, transaction management, and reporting. This is usually accomplished by: 
                            </p>
                            <ol className='mt-3'>
                                <li className='mb-2 ml-3'>
                                Checking customers against known "blacklists" on a regular basis (e.g. Law enforcement Notices).
                                </li>
                                <li className='mb-2 ml-3'>
                                Requesting additional information and documents from customers in the event of suspicious transactions.
                                </li>
                                <li className='mb-2 ml-3'>
                                Ensuring that suspicious transactions are reported to the appropriate authorities.
                                </li>   
                            </ol>
                        </div>

                        <div className='mt-5'>
                            <h6>INTERNAL CONTROL  </h6>
                            <p className='mt-3'>
                            This office is equipped to develop and implement internal controls and other procedures to prevent criminals from utilizing its services for money laundering and terrorist funding, as well as to verify CardBarter's compliance with existing laws and regulations.
                            </p> 
                            <p>
                            This anti-money laundering policy is subject to change, so customers should check back frequently to ensure they are up to date on our terms of service.
                            </p>   
                            <p>
                            For enquiries, observations and comments, contact us via <a href='mailto:support@cardbarter.com'>support@cardbarter.com</a>.
                            </p>  
                        </div>



                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}
 
export default AMLPolicyPage;