import * as Yup from 'yup'

export const loginValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
})

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
    .min(6, 'Password cannot be less than 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const registerValidator = Yup.object({
  phone: Yup.string()
    .max(11, 'Exceeded the characters for phone number')
    .required('Phone number is required')
    .matches(/^\d+(\.\d+)*$/, 'Enter a valid number'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstname: Yup.string().required('Firstname is required'),
  lastname: Yup.string().required('Lastname is required'),
  password: Yup.string()
    .min(6, 'Password cannot be less than 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const contactValidator = Yup.object({
  firstName: Yup.string().required('Firstname is required'),
  lastName: Yup.string().required('Lastname is required'),
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  message: Yup.string().required('Message is required'),
})

export const rateValidator = Yup.object({
  giftname: Yup.string().required('Giftname is required'),
  category: Yup.string().required('Select a category'),
  amount: Yup.string()
    .required('Enter an amount')
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, 'Enter a valid amount')
    .min(2, 'Minimum amount is $25'),
})

export const bankAccountValidator = Yup.object({
  bankName: Yup.string().required('Bank Name is required'),
  accountNumber: Yup.string()
    .min(10, 'Account number cannot be less than 10 digits')
    .max(10, 'Exceeded characters for Account Number')
    .required('Account Number is required')
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, 'Enter a valid Account Number'),
  accountName: Yup.string().required('Enter your account name'),
})

export const withdrawalValidator = Yup.object({
  amount: Yup.string()
    .required('Enter an amount')
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, 'Enter a valid amount'),
  narration: Yup.string(),
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
})

export const tradeValidator = Yup.object({
  giftname: Yup.string().required('Giftname is required'),
  category: Yup.string().required('Select a category'),
  amount: Yup.string()
    .required('Enter an amount')
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, 'Enter a valid amount')
    .min(2, 'Minimum amount is $25'),
  comment: Yup.string(),
})

export const filterValidator = Yup.object({
  status: Yup.string().required('Select a status'),
  amount: Yup.string().matches(/^[0-9]*\.?[0-9]*$/, 'Enter a valid amount'),
})

export const ChangePinValidator = Yup.object({
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  newpin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Enter a new pin")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  confirm_pin: Yup.string()
    .required("Pin must match")
    .oneOf([Yup.ref("newpin"), null], "Pin must match"),
});

export const ChangePasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  newpassword: Yup.string().required("Enter a new password"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
});

export const ResetPinValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .min(11, "Phone number cannot be less than 11 digits")
    .max(11, "Exceeded characters for phone number")
    .required("Phonenumber is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
});

export const ResetTokenValidator = Yup.object({
  code: Yup.string().required("Token is required"),
});
