import React, { useEffect } from 'react'
import verifyImg from '../../assets/images/verify_icon.svg'
import styles from '../Auth/auth.module.css'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import { connect } from 'react-redux'
import { resendLink } from '../../store/actions/auth'


const VerifyAccount = ({email, sendLink, link_status}) => {

  useEffect(()=>{
    document.body.classList.add(styles.verify_body)
  },[])

  const handleSendAgain = () => {
    if (email !== "") {
       const creds = {
         email: email
       };
      sendLink(creds)
    }
}

  return (
    <>
      {' '}
      <div className='contain'>
        <div className={styles.verify_container}>
          <div className={styles.verify_icon_container}>
            <img src={verifyImg} alt="Verify Account" className='img-fluid' />
          </div>
          <div>
            <h3 className={`mt-3 ${styles.verifyacct_p}`}>Verify your account</h3>
            <p className={`mt-3 ${styles.linksent_p}`}>
              Your Account verification link has been sent to your email
              address.
            </p>
            <p className={`mt-3 ${styles.sendagain_p}`}>
              Didn’t get the mail?<span
                className={link_status ? `${styles.link_status} ${styles.link_disabled}` : `${styles.link_status}`}
               onClick={handleSendAgain}> Send it again</span>{' '}
            </p>
          </div>

          <div className={styles.footer}>
            <img src={CBlogo_footer} alt="Logo" />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.signup_email,
    link_status: state.auth.link_status
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendLink: (creds) => dispatch(resendLink(creds)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount)
