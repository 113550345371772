import React from 'react'
import OnBoardLeftLayout from '../../components/OnboardingSidebar/sidebar'
import styles from './auth.module.css'
import CBlogo_footer from '../../assets/images/CB-logo-footer.svg'
import Button from '../../components/Button/Button'
import { Link } from 'react-router-dom'
import email from '../../assets/icons/email_icon.svg'
import { Form, Formik } from 'formik'
import { forgotPasswordValidator } from '../../validationSchema/validator'
import { connect } from 'react-redux'
import { forgotPassword } from '../../store/actions/auth'

const ForgotPassword = ({forgot}) => {

  // Forgot Password  func
  const handleSubmit = async (values) => {
    await forgot(values);
  }
  return (
    <>
      <div className={styles.Auth_div}>
        <div className="row no-gutters">
          <div className="col-lg-5 d-none d-lg-block">
            <div>
              <OnBoardLeftLayout classname={styles.onboarding_img} />
            </div>
          </div>
          <div className="col-lg-7">
            <div className={styles.auth_right_sect}>
              <div>
                <Formik
                  onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                  }
                  validationSchema={forgotPasswordValidator}
                  initialValues={{ email: ''}}
                >
                  {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form className={styles.form_div} onSubmit={handleSubmit}>
                      <div>
                        <h2 className={styles.reset_password}>
                          Reset Password
                        </h2>

                        <p className={`mt-3 ${styles.reset_password_}`}>
                          Enter your email address and we will help you reset
                          your password
                        </p>
                      </div>
                      <div className={`mt-4 mb-2 ${styles.input_container}`}>
                        <label>Email</label>
                        <div style={{ position: 'relative' }}>
                          <input
                            type="email"
                            name="email"
                            placeholder="johndoe@gmail.com"
                            className={styles.input_style}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                          />
                          <img
                            src={email}
                            alt="email-icon"
                            className={styles.auth_icon_email}
                          />
                        </div>
                        <small style={{ color: '#dc3545' }}>
                          {touched.email && errors.email}
                        </small>
                      </div>

                      <div className="mt-4">
                        {' '}
                        <Button
                          title="Send Password Reset Link"
                          disabled={isSubmitting}
                        />
                      </div>

                      <div className="text-center mt-3">
                        <p className={styles.form_div_signup}>
                          Remember password?{' '}
                          <Link to="/login" className="ml-1">
                            Back to Login
                          </Link>
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              <div className={styles.footer}>
                <img src={CBlogo_footer} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgot: (creds) => dispatch(forgotPassword(creds)),
  };
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
